import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toolbar from '../layout/Toolbar';
import LoadingBar from '../layout/LoadingBar';
import config from '../../config';
import { API } from 'aws-amplify';

class NotificationsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
      isSaved: false,
      isSaving: false,
      notificationsStatus: {}
    };
  }

  getData() {
    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/getNotificationsStatus';
    let body = {
      body: {}
    };

    API.get(apiName, path, body).then(response => {
      this.setState({ isLoaded: true, notificationsStatus: response.notificationSettings });
    }).catch(error => {
      console.log("error", error);
    });
  }

  async componentDidMount() {
    const { project } = this.props;
    if (project) {
      this.getData();
    }
  }
  saveSettings() {
    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/setNotificationsStatus';
    let body = {
      body: {
        notificationSettings: this.state.notificationsStatus
      }
    };
    
    this.setState({ isSaving: true });
    
    API.post(apiName, path, body).then(response => {
      console.log(response);
      this.setState({ isSaving: false, isSaved: true });
    }).catch(error => {
      console.log("error", error);
    });
    return true;
  }

  toggleProfileMenu() {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  }
  checkboxHandle(setting) {
    let { notificationsStatus } = this.state;
    notificationsStatus[setting] = !notificationsStatus[setting];
    this.setState({ notificationsStatus: notificationsStatus });
  }
  render() {
    const user = this.props.auth.user;
    let { projects, hasLeadsBot } = this.props;
    let { isLoaded, isSaved, isSaving, notificationsStatus, project } = this.state;

    return (!projects ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      <div className="content mobile-menu-main">
        <div className="container">
            <div className="spacing"></div>
            {isLoaded && project &&
            <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={project}/>}
            <div className="spacing spacing--small"></div>
            <h1>
              <span className="mr-1">Notifikationer</span>
            </h1>
        
            <div className="mw-paragraph">
              Välj ett av dina projekt nedan för att se er data.
            </div>
            <div className="mw-paragraph">
              Välj ett av dina projekt nedan för att se er data.
            </div>
            <div className="spacing spacing--extra-large"></div>
            {isLoaded && hasLeadsBot &&(
            <div>
              <h2>Leads </h2>
              <div>
                <span>
                  Epost
                  <span className="checkbox checkbox__inline">
                    <input type="checkbox" checked={notificationsStatus && notificationsStatus.leadsEmail} onChange={() => this.checkboxHandle('leadsEmail')}/>
                    <span><FontAwesomeIcon icon={['fas','check']}/></span>
                  </span>
                </span>
                
              </div>
            <div class="spacing spacing--extra-large"></div>
            </div>
            )}
            {isSaved ? (
            <div>
              Inställningar sparade!
            </div>): 
            <button disabled={isSaving} className="button btn" style={{padding:'.5em 1em', margin: '0 1em'}} onClick={() => this.saveSettings()}>Spara Inställningar</button>}
              
          </div>
        </div>
    ));
  }
}
export default NotificationsSettings;
