import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import config from "./config";
import * as serviceWorker from './serviceWorker';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [{
        name: "analytics-api",
        endpoint: "https://api.zmashsolutions.com/analytics",
        service: "lambda",
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` };
        }
      },
      {
        name: "analytics-api-dev",
        endpoint: "https://api.zmashsolutions.com/analytics-dev",
        service: "lambda",
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` };
        }
      }
    ]
  }
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
