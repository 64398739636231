import React from 'react';
import FormErrors from "./FormErrors";
import Validate from "./FormValidation";
import { Auth } from "aws-amplify";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    const user = props.auth.user;
    if (!props.auth.isAuthenticated || !(user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED')) {
      this.props.history.push("/");
    }
    this.state = {
      newpassword: "",
      confirmpassword: "",
      firstname: "",
      lastname: "",
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    };
  }
  async componentDidMount() {
    console.log(this.props);
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      const user = this.props.auth.user;
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        this.state.newpassword, // the new password
        {
          name: this.state.firstname,
          family_name: this.state.lastname,
        }
      );
      const newUser = await Auth.currentAuthenticatedUser();
      this.props.auth.setLogin(newUser, () => { this.props.history.push("/overview") });
    }
    catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: { ...this.state.errors, cognito: err }
      });
      console.log(err);
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {

    return (
      <div className="content mobile-menu-main">
        <div className="spacing">
      </div>

      <div className="container">
        <h1>Färdigställ din profil</h1>
          <div className="mw-paragraph">
            Då det är första gången som du loggar in så måste vi be dig att fylla i lite uppgifter om dig själv. Använd formuläret nedan för att dela med dig av ditt för-och efternamn och välj ditt nya lösenord.
          </div>

          <div className="spacing spacing--medium"></div>

          <div className="row small-gutters">
            <div className="col-lg-6">
                  <div className="full-height-in-column">
                    <div className="card card--fill">
                      <div className="d-flex align-items-center no-gutters card__content metric">
                        <section className="section auth">
                          <div className="field">
                            <p className="control">
                              <input 
                                className="input" 
                                type="text"
                                id="firstname"
                                placeholder="Förnamn"
                                value={this.state.firstname}
                                onChange={this.onInputChange}
                              />
                            </p>
                          </div>
                          <div className="field">
                            <p className="control">
                              <input 
                                className="input" 
                                type="text"
                                id="lastname"
                                placeholder="Efternamn"
                                value={this.state.lastname}
                                onChange={this.onInputChange}
                              />
                            </p>
                          </div>
                          <h2>Nytt lösenord</h2>
                          
                          <form onSubmit={this.handleSubmit}>
                            <div className="field">
                              <p className="control has-icons-left">
                                <input
                                  className="input"
                                  type="password"
                                  id="newpassword"
                                  placeholder="Nytt lösenord"
                                  value={this.state.newpassword}
                                  onChange={this.onInputChange}
                                />
                                <span className="icon is-small is-left">
                                  <i className="fas fa-lock"></i>
                                </span>
                              </p>
                            </div>
                            <div className="field">
                              <p className="control has-icons-left">
                                <input
                                  className="input"
                                  type="password"
                                  id="confirmpassword"
                                  placeholder="Bekräfta lösenord"
                                  value={this.state.confirmpassword}
                                  onChange={this.onInputChange}
                                />
                                <span className="icon is-small is-left">
                                  <i className="fas fa-lock"></i>
                                </span>
                              </p>
                            </div>
                            <FormErrors formerrors={this.state.errors} />
                            <div className="field">
                              <p className="control">
                                <button className="button is-success">
                                  Färdigställ profil
                                </button>
                              </p>
                            </div>
                          </form>
                      </section>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
export default NewUser;
