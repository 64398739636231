import React from 'react';
import { Link } from "react-router-dom";

function JobRow(props){
   return (
      <tr>
        <td className="table__mobile-heading">
          <Link to={`/job/${props.job.JobId}`} className="font-weight-bold text-black">
            {props.job.title}
          </Link>
        </td>
        <td>
          {props.job.city}
        </td>
        <td>
          <Link to="/candidates-overview" className="text-primary">{props.job.applications} Kandidater</Link>
        </td>
      </tr>
   );
}

export default JobRow;
