import React from 'react';
import './App.css';
import './scss/main.scss';
import './scss/login.scss';
import Home from './components/home/Home';
import Login from './components/home/Login';
import ForgotPassword from './components/home/ForgotPasswordRequest';
import ResetPassword from './components/home/ResetPassword';
import NlpFlow from './components/NlpFlow/NlpFlow';
import FacebookMessenger from './components/FacebookMessenger/FacebookMessenger';
import FacebookLogin from './components/FacebookLogin/FacebookLoginOverview';
import NewUser from './components/home/NewUser';
import ProjectsOverview from './components/ProjectsOverview/ProjectsOverview';
import CandidatesOverview from './components/Candidates/CandidatesOverview';
import LeadsOverview from './components/Leads/LeadsOverview';
import JobsOverview from './components/Jobs/JobsOverview';
import JobFlow from './components/Jobs/JobFlow';
import BrandConversion from './components/BrandConversion/BrandConversion';
import BrandEngagement from './components/BrandEngagement/BrandEngagement';
import Insights from './components/Insights/Insights';
import Flows from './components/Flows/Flows';
import NotificationsSettings from './components/NotificationsSettings/NotificationsSettings';
import Gdpr from './components/Gdpr/Gdpr';
import FlowEditor from './components/FlowEditor/FlowEditor';
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { Auth, API } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import PrivateRoute from './PrivateRoute';
import config from './config';

import {
  faFacebookMessenger,
  faFacebook
}
from '@fortawesome/free-brands-svg-icons';

import {
  faChartBar,
  faClock,
  faComment,
  faComments,
  faEye,
  faFileAlt,
  faHandPointer,
  faTrashAlt,
  faEyeSlash
}
from '@fortawesome/free-regular-svg-icons';


import {
  faBars,
  faBullseye,
  faCaretDown,
  faCaretUp,
  faChartPie,
  faCheck,
  faChevronRight,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCodeBranch,
  faCompass,
  faCog,
  faDivide,
  faEquals,
  faFilter,
  faGlobe,
  faHandHoldingHeart,
  faLifeRing,
  faProjectDiagram,
  faPlus,
  faSearchDollar,
  faSignOutAlt,
  faSuitcase,
  faSpinner,
  faTasks,
  faTimes,
  faUsers,
  faUserFriends,
  faUserSecret
}
from '@fortawesome/free-solid-svg-icons';

library.add(
  faFacebook,
  faFacebookMessenger,
  faBars,
  faBullseye,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faChartPie,
  faCheck,
  faChevronRight,
  faClock,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCodeBranch,
  faComment,
  faCompass,
  faCog,
  faComments,
  faDivide,
  faEye,
  faEyeSlash,
  faEquals,
  faFilter,
  faFileAlt,
  faGlobe,
  faHandHoldingHeart,
  faHandPointer,
  faLifeRing,
  faPlus,
  faProjectDiagram,
  faSpinner,
  faSearchDollar,
  faSignOutAlt,
  faSuitcase,
  faTasks,
  faTimes,
  faTrashAlt,
  faUsers,
  faUserFriends,
  faUserSecret
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isLoaded: false,
      user: null,
      project: null
    };
  }

  userProjectInit() {
    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/projects';
    let myInit = {};
    if (this.state.user) {

      API.post(apiName, path, myInit).then(response => {
        if (response.projects.length > 0) {
          var lastProject = window.sessionStorage.getItem("lastProject");
          if (lastProject) {
            lastProject = JSON.parse(lastProject);
          }
          this.setState({
            isLoaded: true,
            project: lastProject,
            projects: response.projects,
            hasLeadsBot: response.hasLeadsBot,
            hasApplicationBot: response.hasApplicationBot
          });
        }
      }).catch(error => {});
      this.setUser(this.state.user);
    }
    else {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.setState({ isLoaded: true });
    }
  }
  async componentDidMount() {
    try {
      // const session = await Auth.currentSession();
      await Auth.currentSession();
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);

      // try to set project
      this.userProjectInit();

    }
    catch (error) {
      this.setState({ isLoaded: true });
      if (error !== 'No current user') {}
    }

    this.setState({ isAuthenticating: false });
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
    if (!authenticated) {
      this.setState({ project: null, projects: [] });
    }
  }

  setUser = user => {
    this.setState({ user: user, isAdmin: (user.attributes && user.attributes['custom:client_id']) ? user.attributes['custom:client_id'] === 'admin' : false });
  }

  setLogin = (user, callback) => {
    if (callback) {
      this.setState({
        user: user,
        isAuthenticated: true
      }, () => {
        this.userProjectInit();
        callback();
      });
    }
    else {
      this.setState({
        user: user,
        isAuthenticated: true
      }, () => this.userProjectInit());
    }
  }

  setProject(project) {
    this.setState({ project: project });
    window.sessionStorage.setItem("lastProject", JSON.stringify(project));
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAdmin: this.state.isAdmin,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      setLogin: this.setLogin
    };
    const { isAuthenticating, isLoaded, projects, project } = this.state;

    return (!isAuthenticating && isLoaded &&
      <Router>
          <Route exact path="/" render={(props) => <Login {...props} auth={authProps} />} />
          <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
          <Route exact path="/reset-password/:vc" render={(props) => <ResetPassword {...props} auth={authProps} />} />
          <PrivateRoute exact path="/new-user" component={NewUser}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
          />
          <PrivateRoute exact path="/overview" component={Home}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
          />
          <PrivateRoute exact path="/guiding-flow" component={NlpFlow}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
          />
          <PrivateRoute exact path="/jobs-overview" component={JobsOverview}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            auth={authProps} project={project} projects={projects} 
          />
          <PrivateRoute exact path="/job/:jobId" component={JobFlow}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
          />
          <PrivateRoute exact path="/candidates-overview" component={CandidatesOverview}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/leads-overview" component={LeadsOverview}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/projects" component={ProjectsOverview}
            auth={authProps} project={project} projects={projects} setProject={this.setProject.bind(this)} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/notifications" component={NotificationsSettings}
            auth={authProps} project={project} projects={projects} 
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/engagement-rate" component={BrandEngagement}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/conversion-rate" component={BrandConversion}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/insights" component={Insights}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/facebook" component={FacebookMessenger}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/facebook-login" component={FacebookLogin}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/personal-data-change-requests" component={Gdpr}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/flows" component={Flows}
            auth={authProps} project={project} projects={projects}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
          <PrivateRoute exact path="/flow-editor/:flowVersionId" component={FlowEditor}
            auth={authProps} project={project} projects={projects} noInterface={true}
            hasLeadsBot={this.state.hasLeadsBot} hasApplicationBot={this.state.hasApplicationBot}
            />
        </Router>
    );
  }
}

export default withRouter(App);
