import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from "react-onclickoutside";

class FlowSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedFlow: this.props.selectedFlow
    };
  }

  toggleDrowDown() {
    const newState = !this.state.showDropDown;
    this.setState({ showDropDown: newState });
  }
  handleClickOutside = evt => {
    if (this.state.showDropDown === true) {
      this.setState({showDropDown:false});
    }
  };
  selectFlow(flow){
    this.props.selectFlow(flow);
    console.log(flow);
    this.toggleDrowDown();
  }
  render() {
    const { flows, selectedFlow } = this.props;
    
    return (
      <div className={ this.state.showDropDown? 'dropdown dropdown--active': 'dropdown' }>
        <button onClick={() => this.toggleDrowDown()}  className="dropdown__trigger button button--small button--outlined button--less-rounded">
          {selectedFlow.name}
          <FontAwesomeIcon className="text-muted" icon={['fas','caret-down']} fixedWidth  />
        </button>
       
        <div className="dropdown__popup dropdown__popup--align-center">
          <div className="dropdown__hatch">
            <FontAwesomeIcon icon={['fas','caret-up']} fixedWidth  />
          </div>
      
          <div className="dropdown__links dropdown--colored-options">
            { flows && flows.map(flow => {
                return (
                <button onClick={() => this.selectFlow(flow.FlowVersionId)} key={flow.FlowVersionId} className="font-weight-medium no-underline d-block active-in-list">
                  {flow.name}
                </button>);
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
export default onClickOutside(FlowSelector);
