import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';

const CommentsList = (props) => {
  function removeComment(index) {
    const { removeComment } = props;
    removeComment(index);
  }
  const { comments, tz, isLoading } = props;
  return (
    <div className={ isLoading ? "comment__list--loading": "comment__list" }>
      { comments && comments.map( (comment,index) => {
          return (
            <div className="comment" key={index}>
              <div className="comment__text">
                <div className="comment__bubble">
                  { comment.comment }
                </div>
                <button 
                  className="button btn-link button--x-small button button--pointer button--padding-zero button--outlined button--no-focus-outline"
                  onClick={()=> removeComment(index)}
                >
                  <FontAwesomeIcon icon={['far','trash-alt']}/>
                </button>
                
              </div>
              
              <div className="comment__footer">
                <div className="comment__date">
                  <Moment unix tz={tz} format="YYYY-MM-DD HH:mm:SS">
                  { comment.timestamp/1000 }
                  </Moment> 
                </div>
                <div className="comment__comment-by">
                  { comment.by }
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default CommentsList;
