import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false
    };
  }
  handleLogOut = async(event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    }
    catch (error) {
      console.log(error.message);
    }
  }
  toggleProfileMenu() {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  }

  render() {
    const { user } = this.props.auth;
    const showBetaFeatures = this.props.projects ? (this.props.projects.find(p => p.enableBetaFeatures ? p.enableBetaFeatures === true : false) || false) : false;

    return (
      <div className="d-xl-none mobile-menu">
        <div className="container">
          <div className="spacing spacing--small"></div>
          <Link to="/overview" className={this.props.path === "/overview" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon icon={['far','chart-bar']} fixedWidth /> Dashboard
          </Link>
          { this.props.project && this.props.project.hasApplicationBot &&
          <Link to="/guiding-flow" className={this.props.path === "/guiding-flow" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon  icon={['fas','project-diagram']} fixedWidth /> Navigeringsflöde
          </Link>
          }
          { this.props.project && this.props.project.hasApplicationBot &&
          <Link to="/jobs-overview" className={this.props.path === "/jobs-overview" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon  icon={['fas','suitcase']} fixedWidth /> Jobbflöden
          </Link>
          }
          { this.props.project && this.props.project.hasApplicationBot &&
           <Link to="/candidates-overview" className={this.props.path === "/candidates-overview" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon  icon={['fas','users']} fixedWidth /> Kandidater
          </Link>
          }
          { this.props.project && this.props.project.hasLeadsBot &&
          <Link to="/leads-overview" className={this.props.path === "/leads-overview" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','search-dollar']} fixedWidth /> Leads
            </div>
          </Link>
          }
          {this.props.project && this.props.project.hasFlows &&
          <Link to="/flows" className={this.props.path === "/flows" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','code-branch']} fixedWidth /> Flows
            </div>
          </Link>
          }
          <Link to="/engagement-rate" className={this.props.path === "/engagement-rate" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
             <FontAwesomeIcon icon={['fas','hand-holding-heart']} fixedWidth /> Engagement Rate
          </Link>
          <Link to="/conversion-rate" className={this.props.path === "/conversion-rate" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon icon={['fas','bullseye']} fixedWidth  /> Conversion Rate
          </Link>
          <Link to="/personal-data-change-requests" className={this.props.path === "/gdpr" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
            <FontAwesomeIcon icon={['fas','user-secret']} fixedWidth  /> Dataändringsbegäran
          </Link>
          { ( showBetaFeatures || this.props.isAdmin )&&
            <Link to="/insights" className={this.props.path === "/insights" ? "mobile-menu__link mobile-menu__link--active": "mobile-menu__link"} onClick={()=> {this.props.toggleMobileMenu()}}>
              <FontAwesomeIcon icon={['fas','chart-pie']} fixedWidth  /> <span className="label">Insights <span className="jewel jewel--text">BETA</span></span>
            </Link>
          }
          <div className="spacing spacing--extra-large"></div>
          <a href="https://zmash.com/faq/" className="button button--small button--tetriary">
            <FontAwesomeIcon icon={['fas','life-ring']} fixedWidth  /> Hjälp
          </a>
          <div className="spacing spacing--extra-large"></div>
        </div>
        {user && user.attributes && (
        <div className="mobile-menu__user">
          <div className="container">
            <div className="row small-gutters justify-content-between">
                <div className={this.state.showProfileMenu ? "dropdown dropdown--active": "dropdown"}>
                  <button href="#" onClick={this.toggleProfileMenu.bind(this)} className="dropdown__trigger text-dark-gray no-underline">
                    {`${user.attributes.name} ${user.attributes.family_name}`}
                    <FontAwesomeIcon icon="caret-down" fixedWidth/>
                  </button>
          
                  <div className="dropdown__popup dropdown__popup--above dropdown__popup--align-right">
                    <div className="dropdown__hatch">
                      <i className="fas fa-caret-up"></i>
                    </div>
          
                    <div className="dropdown__links">
                      <Link to="/projects" className="text-dark-gray font-weight-medium no-underline d-block">
                        <FontAwesomeIcon icon="cog" className="text-muted mr-1" fixedWidth/>
                        Projekt
                      </Link>
                      <button className="text-dark-gray font-weight-medium no-underline d-block" onClick={this.handleLogOut.bind(this)}>
                        <FontAwesomeIcon icon="sign-out-alt" className="text-muted mr-1" fixedWidth/>
                        Logga ut
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>)
        }
      </div>
    );
  }
}
export default Sidebar;
