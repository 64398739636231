import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import { Link } from "react-router-dom";
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import MetricCard from '../layout/MetricCard';
import LoadingBar from '../layout/LoadingBar';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };
  }

  async componentDidMount() {
    const { project, startDate, endDate } = this.props;
    if (project && startDate && endDate) {
      this.getData(startDate, endDate);
    }
  }

  getData(startDate, endDate) {
    const { project, formatDate } = this.props;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/dashboardOverview';
    let myInit = {
      body: { projectId: project.ProjectId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
    };

    API.post(apiName, path, myInit).then(response => {
      this.setState({ isLoaded: true, stats: response.stats, jobs: response.jobs });
    }).catch(error => {
      console.log("error", error);
    });
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  setStartDate(date) {
    this.getData(date, this.props.endDate);
    this.setState({ isLoaded: false });
    this.props.setStartDate(date);
  }
  setEndDate(date) {
    this.getData(this.props.startDate, date);
    this.setState({ isLoaded: false });
    this.props.setEndDate(date);
  }

  render() {
    const user = this.props.auth.user;
    const { project, handleLogOut, projectStartDate, defaultStartDate, startDate, endDate } = this.props;
    const { stats, isLoaded, jobs } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      <div className="content mobile-menu-main">

      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={handleLogOut} user={user} project={project} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} setStartDate={this.setStartDate.bind(this)}  setEndDate={this.setEndDate.bind(this)} />
          <div className="spacing spacing--small"></div>
          <h1>
            <span className="mr-1">Dashboard</span>
          </h1>

          <div className="mw-paragraph">
           Översikten ger dig en vy över er chattbot-statistik oavsett vart boten är placerad eller vad för funktion den har. Datan nedan visar en summering av chattbotresultaten för det här projektet.
          </div>
          <div className="spacing spacing--extra-large"></div>
          <h2>ChattBot-statistik</h2>
          <div className="row small-gutters">
            <MetricCard metricValue={stats.sessions} metricTitle="Sessioner"
              metricDescription="Antal gånger er chattbot har blivit sedd av användare"
              metricIcon={['far','eye']} 
              gradientStart="#ff4d5d" gradientEnd="#ff99a1"
            />
            
            <MetricCard metricValue={stats.conversations} metricTitle="Konversationer"
              metricDescription="Antal gånger användare har interagerat minst en gång med er chattbot"
              metricIcon={['far','comments']} gradientStart="#2d398b" gradientEnd="#00aeef"
            />

            
            { !project.disableAiMetrics && project && project.hasApplicationBot &&
            <MetricCard metricValue={stats.totalClicks} metricTitle="Klick"
              metricDescription="Antal klick på föreslagna jobb"
              metricIcon={['far','hand-pointer']} gradientStart="#ef4136" gradientEnd="#fbaf40"
            />
            }
            
            { !project.disableApplicationMetrics && project && project.hasApplicationBot &&
            <MetricCard metricValue={stats.applications} metricTitle="Ansökningar"
              metricDescription="Antal ansökningar från användare"
              metricIcon={['far','file-alt']} gradientStart="#0fc373" gradientEnd="#15f18f"
            />
            }
            { project && project.hasLeadsBot &&
            <MetricCard metricValue={stats.leads} metricTitle="Leads"
              metricDescription="Antal leads insamlade"
              metricIcon={['fas','search-dollar']} gradientStart="#48399e" gradientEnd="#8868e8"
            />
            }
          </div>
          { !project.disableAiMetrics && jobs.top10 && jobs.top10.length > 0 &&(
          <div className="row">
          
            <div className="col-md-12">
              <h2>Mest populära jobbflöden</h2>
              <div className="full-height-in-column card card__table">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>
                        <span href="#" className="table__not-sortable">Jobb</span>
                      </th>
                      <th>
                        <span href="#" className="table__not-sortable">Plats</span>
                      </th>
                      <th className="text-right">
                        <span href="#" className="table__not-sortable">Startade flöden</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                      { jobs.top10.map(job =>{
                        return(
                          <tr key={job.JobId}>
                            <td className="table__mobile-heading">
                              <Link to={`/job/${job.JobId}`} className="font-weight-bold text-black">
                                {job.title}
                              </Link>
                            </td>
                            <td>{job.city}</td>
                            <td className="text-right">{job.clicks}</td>
                          </tr>);
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>)
          }
          
        </div>
          <div className="spacing spacing--extra-large"></div>
      </div>
    ));
  }
}
export default Home;
