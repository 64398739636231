import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from "react-onclickoutside";

class FilterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      showAddDropDown: false,
    };
  }

  toggleDrowDown() {
    const newState = !this.state.showDropDown;

    this.setState({ showDropDown: newState });
  }

  toggleAddDropDown() {
    const newState = !this.state.showAddDropDown;

    this.setState({ showAddDropDown: newState });
  }

  addvalue(value) {
    let filter = this.props.filter;
    
    filter.isValues.push(value);
    
    this.props.updateFilter(filter, this.props.index);
    this.toggleAddDropDown();
  }
  removeValue(index) {
    let filter = this.props.filter;
    
    filter.isValues.splice(index, 1);
    
    this.props.updateFilter(filter, this.props.index);
  }
  removeFilter() {
    this.setState({ showAddDropDown: false, showDropDown: false });
    
    this.props.removeFilter(this.props.index);
  }
  changeFilter(name) {
    this.props.changeFilter(name, this.props.index);
    this.toggleDrowDown();
  }
  
  handleClickOutside = evt => {
    if (this.state.showDropDown === true || this.state.showAddDropDown === true) {
      this.setState({ showDropDown: false, showAddDropDown:false });
    }
  };
  render() {
    const { filter, fields, field } = this.props;

    return (
      <div className="card card--any-width d-inline-block text-small">
        <div className="card__content">
          <div className="row smaller-gutters text-center text-md-left align-items-center">
            <div className="col-md-auto">
              <span>
                <i className="d-md-none text-muted fas fa-times text-small absolute-right"></i></span>

              <div className={ this.state.showDropDown? 'dropdown dropdown--active': 'dropdown' } >
                { filter.name &&
                <button onClick={() => this.toggleDrowDown()} className="dropdown__trigger text-black font-weight-medium no-underline">
                  {filter.name}
                  <FontAwesomeIcon className="text-muted" icon={['fas','caret-down']} fixedWidth  />
                </button>}
                { !filter.name && 
                <button onClick={() => this.toggleDrowDown()} className="dropdown__trigger text-black font-weight-medium no-underline">
                    Välj ett fält
                  <FontAwesomeIcon className="text-muted" icon={['fas','caret-down']} fixedWidth  />
                </button>}

                <div className="dropdown__popup dropdown__popup--align-center">
                  <div className="dropdown__hatch">
                      <FontAwesomeIcon className="text-muted" icon={['fas','caret-up']} fixedWidth  />
                  </div>

                  <div className="dropdown__links dropdown--colored-options">
                    {fields && fields.map(field =>{
                        return(
                        <button key={field.name} className={ field.name === filter.name ? "font-weight-medium no-underline d-block active-in-list":"font-weight-medium no-underline d-block"} onClick={()=> this.changeFilter(field.name)}>
                          {field.name}
                        </button>);
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-auto">
              är en utav
            </div>
            { filter.name &&
            <div className="col-md">
              {filter.isValues && filter.isValues.map((value, valueIndex)=>{
                return (
                  <button key={valueIndex} className="button button--gray button--no-focus-outline button--smaller button--vertical-spacing full-width-on-mobile">
                    {value.label}
                    <FontAwesomeIcon className="text-muted" icon={['fas','times']} onClick={()=>this.removeValue(valueIndex)} fixedWidth  />
                  </button>);
                }
              )}

              <div className="d-md-inline-block">
                <div className={ this.state.showAddDropDown? 'dropdown dropdown--active': 'dropdown' } >
                  <button onClick={() => this.toggleAddDropDown()} className="dropdown__trigger button button--dashed button--smaller button--vertical-spacing d-block d-md-inline-block full-width-on-mobile">
                    <FontAwesomeIcon className="text-muted" icon={['fas','plus']} fixedWidth  />
                    Add
                  </button>

                  <div className="dropdown__popup dropdown__popup--align-center dropdown__popup--large">
                    <div className="dropdown__hatch">
                      <FontAwesomeIcon className="text-muted" icon={['fas','caret-up']} fixedWidth  />
                    </div>

                    <div className="dropdown__links dropdown--colored-options">

                        { field && field.possibleValues &&  field.possibleValues.map(val => {
                            return <button key={val.value} className="font-weight-medium no-underline d-block" onClick={()=> this.addvalue(val)} >
                              {val.label}
                            </button>;
                          })
                        }
                          
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
            <div className="col-auto d-none d-md-block">
              <div className="spacing"></div>
            </div>
            <div className="col-auto d-none d-md-block">
            <FontAwesomeIcon className="text-muted text-small" icon={['fas','times']} onClick={()=>this.removeFilter()} fixedWidth  />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default onClickOutside(FilterCard);
