import React from 'react';

function DisabledMetrics(props) {
   return (
      <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
        <h1>Du kan inte se den här datan.</h1>
        <p>
         Den här typen av data är tyvärr inte tillgängligt med din nuvarande produkt. Om du vill ta del av den här datan, hör av dig till Fredrik Saatchi (fredrik@zmash.se). 

         Se vår FAQ för att läsa mer.
        </p>
      </div>
      </div>
   );
}

export default DisabledMetrics;
