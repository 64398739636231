import React from 'react';
import Highlighter from "react-highlight-words";
import ChatTextArea from "../ChatTextArea";
import InnerButtonTextArea from "../InnerButtonTextArea";

class MessageWithLink extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   handleChange(event) {
      let { action } = this.props;
      action.content.messageContent = event.target.value;
      this.setState({ action: action });
   }

   handleAnswerChange(event, index) {
      let { action } = this.props;
      action.content.messageLinkCta = event.target.value;
      this.setState({ action: action });
   }
   render() {
      let { action } = this.props;

      return (
         <div className="action">
            <ChatTextArea messageContent={action.content.messageContent} changeHandler={this.handleChange.bind(this)}/>
            <div className="link-cta">
                <InnerButtonTextArea answerContent={action.content.messageLinkCta} changeHandler={this.handleAnswerChange.bind(this)} index={0}/>
            </div>
         </div>
      );
   }
}
export default MessageWithLink;
