import React from 'react';
import { API } from 'aws-amplify';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LeadsShowHide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  toggleHide() {
    const { projectId, lead, refilterLeadsList} = this.props;
    console.log(projectId, lead);
    this.setState({ isLoading: true });
    const displayProperties  = lead.displayProperties ||{};
    
    const currentState = displayProperties.hideInDashboard || false;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/toggleLeadsHide';
    let post = {
      body: {
        projectId: projectId,
        biid: lead.biid,
        leadId: lead.LeadId,
        hideInDashboard: !currentState
      }
    };
    lead.displayProperties = !currentState;

    API.post(apiName, path, post).then(response => {
      lead.displayProperties = response.displayProperties;
      this.setState({ isLoading: false });
      refilterLeadsList();
    });
  }

  render() {
    const { lead, projectId } = this.props;
    const { isLoading } = this.state;
    const isHidden = (lead.displayProperties && lead.displayProperties.hideInDashboard)

    return (
      <button onClick={() => this.toggleHide()} 
          className={isHidden ? "btn-link text-dark-gray": "btn-link text-dark-gray show-on-hover"} disabled={isLoading}>
          <FontAwesomeIcon icon={['far','eye-slash']}/>
      </button>
    );
  }
}

export default LeadsShowHide;
