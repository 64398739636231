import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';
import 'moment-timezone';


class Flows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
      modal: null,
      personalDataModal: null,
      commentsModal: null,
      totalSelected: 0,
      allChecked: false,
      filters: []
    };
  }

  async componentDidMount() {
    if (this.props.project && !this.props.project.disableleadMetrics) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/getFlowVersions';
      let myInit = {
        body: { projectId: this.props.project.ProjectId }
      };

      API.post(apiName, path, myInit).then(response => {
        this.setState({
          isLoaded: true,
          flows: response.flows,
        });
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ isLoaded: true });
    }
  }

  getFlowLink(flow) {
    const { project } = this.props;
    if (project) {
      return `/flow-editor/${flow.FlowVersionId}?k=${flow.key}&p=${project.ProjectId}`;
    }
    else return '#';

  }

  render() {
    const user = this.props.auth.user;
    const { project } = this.props;
    const { isLoaded, flows } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableleadMetrics ? <DisabledMetrics /> : (
        <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>

           <div className="row no-gutters align-items-center">
             <div className="col-sm-auto">
               <h1 className="mb-0">Flöden</h1>
             </div>
             <div className="col-sm-auto">
               <div className="h-spacing h-spacing--medium"></div>
             </div>
           </div>
          <div className="spacing"></div>
          
          <div className="spacing"></div>
          
          <div className="card card__table text-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th><span className="table__sortable">Flöde</span></th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
                { flows.map( (flow,index) => {
                  return (
                  <tr key={index}>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Flöde
                    </div>
                      { flow.name }
                  </td>
                  <td className="text-right d-none d-lg-table-cell">
                    <a href={this.getFlowLink(flow)} className="button button--x-small" style={{"padding": "0.5em 1em", "margin": "0px;"}}>
                      Redigera <FontAwesomeIcon icon={['fas','chevron-right']}/>
                    </a>
                  </td>
                </tr>)})
                }
              </tbody>
            </table>
            <div className="separator-h"></div>
            <div className="card__h-content">
              <div className="row justify-content-between align-items-center">
              </div>
            </div>
          </div>
        </div>
      </div>
      )));
  }
}
export default Flows;
