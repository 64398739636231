import React from 'react';

function CandidateFunnel(props) {
    const { funnel, getPercentOf } = props;
    return (
        <div className="side-scroll__content">
              <div className="d-flex">
                <div className="card card--vertical card--fixed-width">
                  <div className="c-spacing c-spacing--medium" style={{padding: '0 10px', height: '9em'}}>
                    <div className="step">
                      <div className="step__circle">
                        1
                      </div>
                      <div className="h-spacing h-spacing--small"></div>
                      <b>Sessioner</b>
                    </div>
                    <div className="spacing spacing--small"></div>
                    <div className="separator-h"></div>
                    <div className="spacing spacing--small"></div>
                    AI har inlett <div className="text-primary font-weight-bold d-inline-block">{funnel.sessions} konversationer</div>
                    <div className="spacing spacing--small"></div>
                  </div>
                  <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                      <path d={`M0 ${"0"}L100 ${getPercentOf(funnel.conversations, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                    </mask>
                    <g mask="url(#mask1)">
                      <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>

                      
                      <line x1="0" y1="0" x2="100" y2={getPercentOf(funnel.conversations, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                    </g>
                    <defs>
                      <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#7661ED"></stop>
                        <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                
                <div className="h-spacing h-spacing--medium"></div>
                
                <div className="card card--vertical card--fixed-width">
                  <div className="c-spacing c-spacing--medium" style={{padding: '0 10px', height: '9em'}}>
                    <div className="step">
                      <div className="step__circle">
                        2
                      </div>
                      <div className="h-spacing h-spacing--small"></div>
                      <b>Konversationer</b>
                    </div>
                    <div className="spacing spacing--small"></div>
                    <div className="separator-h"></div>
                    <div className="spacing spacing--small"></div>
                    <div className="text-primary font-weight-bold d-inline-block">
                      {funnel.conversations} användare
                    </div> har interagerat minst en gång med AI
                    <div className="spacing spacing--small"></div>
                  </div>
                  <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                      <path d={`M0 ${getPercentOf(funnel.conversations, funnel.sessions)}L100 ${getPercentOf(funnel.foundJobs, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                    </mask>
                    <g mask="url(#mask2)">
                      <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                      <line x1="0" y1={getPercentOf(funnel.conversations, funnel.sessions)} x2="100" y2={getPercentOf(funnel.foundJobs, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                    </g>
                  </svg>
                </div>
                
                <div className="h-spacing h-spacing--medium"></div>
                
                <div className="card card--vertical card--fixed-width">
                  <div className="c-spacing c-spacing--medium" style={{padding: '0 10px', height: '9em'}}>
                    <div className="step">
                      <div className="step__circle">
                        3
                      </div>
                      <div className="h-spacing h-spacing--small"></div>
                      <b>Jobbförslag</b>
                    </div>
                    <div className="spacing spacing--small"></div>
                    <div className="separator-h"></div>
                    <div className="spacing spacing--small"></div>
                    <div className="text-primary font-weight-bold d-inline-block">
                      {funnel.foundJobs} användare
                    </div> har fått jobbförslag
                    <div className="spacing spacing--small"></div>
                  </div>
                  <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                      <path d={`M0 ${getPercentOf(funnel.foundJobs, funnel.sessions)}L100 ${getPercentOf(funnel.startedFlows, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                    </mask>
                    <g mask="url(#mask3)">
                      <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>

                      
                      <line x1="0" y1={getPercentOf(funnel.foundJobs, funnel.sessions)} x2="100" y2={getPercentOf(funnel.startedFlows, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                    </g>
                    <defs>
                      <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#7661ED"></stop>
                        <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                
                <div className="h-spacing h-spacing--medium"></div>
                
                <div className="card card--vertical card--fixed-width">
                  <div className="c-spacing c-spacing--medium" style={{padding: '0 10px', height: '9em'}}>
                    <div className="step">
                      <div className="step__circle">
                        4
                      </div>
                      <div className="h-spacing h-spacing--small"></div>
                      <b>Startat ansökan</b>
                    </div>
                    <div className="spacing spacing--small"></div>
                    <div className="separator-h"></div>
                    <div className="spacing spacing--small"></div>
                    <div className="text-primary font-weight-bold d-inline-block">
                      {funnel.startedFlows} användare
                    </div> har påbörjat en ansökning
                    <div className="spacing spacing--small"></div>
                  </div>
                  <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask4" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                      <path d={`M0 ${getPercentOf(funnel.startedFlows, funnel.sessions)}L100 ${getPercentOf(funnel.applicationsCount, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                    </mask>
                    <g mask="url(#mask4)">
                      <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>

                      
                      <line x1="0" y1={getPercentOf(funnel.startedFlows, funnel.sessions)} x2="100" y2={getPercentOf(funnel.applicationsCount, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                    </g>
                    <defs>
                      <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#7661ED"></stop>
                        <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                
                <div className="h-spacing h-spacing--medium"></div>
                
                <div className="card card--vertical card--fixed-width">
                  <div className="c-spacing c-spacing--medium" style={{padding: '0 10px', height: '9em'}}>
                    <div className="step">
                      <div className="step__circle">
                        5
                      </div>
                      <div className="h-spacing h-spacing--small"></div>
                      <b>Kandidater</b>
                    </div>
                    <div className="spacing spacing--small"></div>
                    <div className="separator-h"></div>
                    <div className="spacing spacing--small"></div>
                    <div className="text-primary font-weight-bold d-inline-block">
                      {funnel.applicationsCount} användare
                    </div> blev kandidater
                    <div className="spacing spacing--small"></div>
                  </div>
                  <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask5" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                      <path d={`M0 ${getPercentOf(funnel.applicationsCount, funnel.sessions)}L100 ${getPercentOf(funnel.applicationsCount, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                    </mask>
                    <g mask="url(#mask5)">
                      <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>

                      
                      <line x1="0" y1={getPercentOf(funnel.applicationsCount, funnel.sessions)} x2="100" y2={getPercentOf(funnel.applicationsCount, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                    </g>
                    <defs>
                      <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#7661ED"></stop>
                        <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                
              </div>

              <div className="spacing"></div>
              
              <div className="spacing"></div>
              
            </div>);
}

export default CandidateFunnel;
