import React from 'react';
import Message from './actions/Message';
import MessageWithLink from './actions/MessageWithLink';
import Question from './actions/Question';
import CollectQuestionFixed from './actions/CollectQuestionFixed';
import CollectQuestionFree from './actions/CollectQuestionFree';
import SaveApplication from './actions/SaveApplication';
import SaveLead from './actions/SaveLead';
import GoToNode from './actions/GoToNode';
import PersonalDataConsent from './actions/PersonalDataConsent';


class FlowNode extends React.Component {
   constructor(props) {
      super(props);
   }

   editNode() {
      this.props.editNode(this.props.node);
   }
   getActionComponent(action, index) {
      switch (action.actionType) {
         case 'message':
            return <Message action={action} actionIndex={index} />;
         case 'messageWithLink':
            return <MessageWithLink action={action} actionIndex={index} />;
         case 'question':
            return <Question action={action} node={this.props.node} actionIndex={index} />;
         case 'collectQuestionFixed':
            return <CollectQuestionFixed action={action} node={this.props.node} actionIndex={index} />;
         case 'collectQuestionFree':
            return <CollectQuestionFree action={action} node={this.props.node} actionIndex={index} />;
         case 'saveApplication':
            return <SaveApplication action={action} node={this.props.node} actionIndex={index} />;
         case 'saveLead':
            return <SaveLead action={action} node={this.props.node} actionIndex={index} />;
         case 'goToNode':
            return <GoToNode action={action} node={this.props.node} actionIndex={index} />;
         case 'personalData':
            return <PersonalDataConsent action={action} node={this.props.node} actionIndex={index} />;
         default:
            return <Message action={action} actionIndex={index} />;
      }
   }

  handleChange(event, checkbox = false) {
    let {node} = this.props;
    node[event.target.name] = event.target.value;
  }
   render() {
      let { node } = this.props;
      return (
         <div className={node.isDragging ? "flow-node-inner-wrapper is-dragging":"flow-node-inner-wrapper"}
            id={node.id}
          >
            <div id={'node_'+node.id} className="flow-node"         
            >
               <h3>{node.name}</h3>
               {node.type === 'id' && (
               <div className="isId">
                  ID
               </div>
               )}
               {node.actions && node.actions.length === 0 && (
               <div className="message subtle">
                  no actions
               </div>
               )}
               {node.actions && node.actions.length > 0 && (
               <div className="actions">
                  {
                    node.actions.map((action,index) => {
                      return <div key={'action_'+index}>
                        {this.getActionComponent(action, index)}
                      </div>;
                    })
                  }
              </div>
              )}
            </div>
         </div>

      );
   }
}
export default FlowNode;
