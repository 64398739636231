import React from 'react';
import 'moment-timezone';

class CommentsComposer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    };
  }
  
  onKeyPress(event) {
    const { isLoading } = this.props;
    const { inputValue } = this.state;

    if (event.key !== 'Enter') { return; }
    else if (!isLoading && inputValue.length > 0) {
      this.saveComment();
    }
  }
  
  handleChange(event) {
    const val = event.target.value.slice(0, 500);
    this.setState({ inputValue: val });
  }
  
  saveComment() {
    const { saveComment } = this.props;
    let { inputValue } = this.state;
    saveComment(inputValue);
    this.setState({ inputValue: '' });
  }
  
  render() {
    const { isLoading } = this.props;
    const { inputValue } = this.state;
    return (
      <div className="comment__composer">
        <input 
            type="text" 
            id="addComment" 
            value={this.state.inputValue}
            placeholder="Skriv en kommentar"
            onChange={this.handleChange.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
            disabled={isLoading}
            maxLength="500"
         />
        <button className="button button--less-rounded button--x-small button--no-focus-outline"
          disabled={isLoading || inputValue.length === 0}
          onClick={()=>this.saveComment()}
        >Lägg till</button>
      </div>
    );
  }
}

export default CommentsComposer;
