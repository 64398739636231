import React from 'react';
import FlowSelector from './InsightsFlowSelector';

function InsightsHeader(props) {
  return (
    <div className="row no-gutters align-items-center">
      <div className="col-sm-auto">
        <h1 className="mb-0">Insights</h1>
      </div>
      <div className="col-sm-auto">
        <div className="h-spacing h-spacing--medium"></div>
      </div>
      <div className="col-sm-auto">
        <div className="d-sm-none spacing"></div>
        <FlowSelector flows={props.flows} selectedFlow={props.selectedFlow} selectFlow={props.selectFlow} />
      </div>
    </div>
  );
}

export default InsightsHeader;
