import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Sidebar = (props) => {
  const showBetaFeatures = props.projects ? (props.projects.find(p => p.enableBetaFeatures ? p.enableBetaFeatures === true : false) || false) : false;

  return (
    <div className="sidebar">
      <div>
        <div className="spacing spacing--large"></div>
        <div className="c-spacing logo-container">
          <Link to="/"><img src="/images/logo-white.svg" alt="Zmash" /></Link>
        </div>
          <div className="spacing"></div>
        <div>
          <Link to="/overview" className={props.path === "/overview" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon icon={['far','chart-bar']} fixedWidth /> Dashboard
            </div>
          </Link>
          {props.project && props.project.hasApplicationBot &&
          <Link to="/guiding-flow" className={props.path === "/guiding-flow" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','project-diagram']} fixedWidth /> Navigeringsflöde
            </div>
          </Link>
          }
          {props.project && props.project.hasApplicationBot &&
          <Link to="/jobs-overview" className={props.path === "/jobs-overview" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','suitcase']} fixedWidth /> Jobbflöden
            </div>
          </Link>
          }
          {props.project && props.project.hasApplicationBot &&
          <Link to="/candidates-overview" className={props.path === "/candidates-overview" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','users']} fixedWidth /> Kandidater
            </div>
          </Link>
          }
          {props.project && props.project.hasLeadsBot &&
          <Link to="/leads-overview" className={props.path === "/leads-overview" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','search-dollar']} fixedWidth /> Leads
            </div>
          </Link>
          }
          {props.project && props.project.hasFlows &&
          <Link to="/flows" className={props.path === "/flows" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon  icon={['fas','code-branch']} fixedWidth /> Flöden
            </div>
          </Link>
          }
          <Link to="/engagement-rate" className={props.path === "/engagement-rate" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon icon={['fas','hand-holding-heart']} fixedWidth /> Engagement Rate
            </div>
          </Link>
          <Link to="/conversion-rate" className={props.path === "/conversion-rate" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon icon={['fas','bullseye']} fixedWidth  /> Conversion Rate
            </div>
          </Link>
          <Link to="/personal-data-change-requests" className={props.path === "/gdpr" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon icon={['fas','user-secret']} fixedWidth  /> Dataändringsbegäran
            </div>
          </Link>
          { ( showBetaFeatures || props.isAdmin )&&
          <Link to="/insights" className={props.path === "/insights" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
            <div className="sidebar__label-container">
              <FontAwesomeIcon icon={['fas','chart-pie']} fixedWidth  /> <span className="label">Insights <span className="jewel jewel--text">BETA</span></span>
            </div>
          </Link>
          }
        </div>
      </div>
      <div className="sidebar__bottom">
        <a href="https://zmash.com/faq/" className="button button--small button--tetriary">
          <FontAwesomeIcon icon={['fas','life-ring']} fixedWidth  /> Hjälp
        </a>
      </div>
    </div>
  );
};
export default Sidebar;

          // <Link to="/facebook" className={props.path === "/facebook" ? "sidebar__link sidebar__link--active": "sidebar__link"}>
          //   <div className="sidebar__label-container">
          //     <FontAwesomeIcon  icon={['fab','facebook-messenger']} fixedWidth /> Facebook Messenger
          //   </div>
          // </Link>
          
