import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterCard from './LeadsFilterCard';

class FilterTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
    };
  }

  toggleDrowDown() {
    const newState = !this.state.showDropDown;
    this.setState({ showDropDown: newState });
  }

  addFilter() {
    let { filters } = this.props;
    filters.push({});
    this.props.updateFilters(filters);
  }
  updateFilter(filter, index) {
    let { filters } = this.props;
    filters[index] = filter;
    this.props.updateFilters(filters);
    this.props.filterHasChanged(true);
  }
  changeFilter(name, index) {
    let { filters } = this.props;
    filters[index].name = name;
    filters[index].isValues = [];
    this.props.updateFilters(filters);
    this.setState({ filters: filters });
    this.props.filterHasChanged(true);
  }

  removeFilter(index) {
    let { filters } = this.props;
    filters.splice(index, 1);
    this.props.updateFilters(filters);
    this.props.filterHasChanged(true);
  }

  render() {
    const { fields, isFiltered, hasFilterChanged } = this.props;
    let { filters } = this.props;
    return (
      <div className='filters'>
        {filters && filters.map((filter, index)=>{
          
          let field = fields.find(v => { return v.name === filter.name} );
          
          return( index > 0 ? (
            <div key={index}>
              <div className="filter__and">
                  OCH
              </div>
              <FilterCard filter={filter} index={index} fields={fields} field={field} usedFilters={this.props.filters.map(f=> f.value)} changeFilter={this.changeFilter.bind(this)} updateFilter={this.updateFilter.bind(this)} removeFilter={this.removeFilter.bind(this)} />
            </div>
            ): 
            ( <FilterCard key={index} filter={filter} index={index} fields={fields} field={field} usedFilters={this.props.filters.map(f=> f.value)} changeFilter={this.changeFilter.bind(this)} updateFilter={this.updateFilter.bind(this)} removeFilter={this.removeFilter.bind(this)}/>)
            );
          })
        }
      <div className="spacing"></div>
          <div>
          { fields && fields.length > 1 &&
          (
            <button className="button button--dashed button--no-focus-outline button--less-rounded button--small button--pointer" onClick={() => this.addFilter()}> 
              <FontAwesomeIcon icon={['fas','filter']} fixedWidth/>Lägg till filter
            </button>
          )}
          { hasFilterChanged && filters.length > 0 &&
          (
            <button className="button button--no-focus-outline button--less-rounded button--small button--pointer" onClick={()=> this.props.applyFilter()} style={{marginLeft:'1em'}}> 
              <FontAwesomeIcon icon={['fas','filter']} fixedWidth/>Applicera filtrering
            </button>
          )}
          { isFiltered &&
          (
            <button className="button button--no-focus-outline button--less-rounded button--gray button--small button--pointer" onClick={()=> this.props.clearFilter()} style={{marginLeft:'1em'}}> 
              <FontAwesomeIcon icon={['fas','filter']} fixedWidth/>Rensa filtrering
            </button>
          )}
          </div>
          <div className="spacing"></div>
      </div>
    );
  }
}
export default FilterTools;
