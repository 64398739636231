import React from 'react';
import uuid from 'uuid';
import FlowNode from './FlowNode.js';

class FlowStep extends React.Component {
  constructor(props) {
    super(props);
    let step = this.props.step;
    step.nodes = step.nodes || [];
    this.state = {
      editName: false
    };
    this.innerRef = React.createRef();
  }

  addNode() {
    let { step } = this.props;
    let nodes = step.nodes || [];

    const count = nodes.length + 1;
    nodes.push({
      name: 'Node ' + count,
      id: uuid.v4(),
      actions: []
    });
    this.setState({ step: step });
  }
  editStep() {
    this.props.editStep(this.props.step);
  }
  deleteStep() {
    let { step } = this.props;
    this.props.deleteStep(step);
  }
  editNode(node) {
    this.props.editNode({ step: this.props.step, node: node });
  }
  toggleEditName() {
    this.setState({ editName: !this.state.editName }, () => {
      if (this.state.editName) {
        this.nameInput.focus();
      }
    });
  }
  leaveEditName() {
    this.setState({ editName: false });
  }
  handleChange(event) {
    let { step } = this.props;
    step[event.target.name] = event.target.value;
    // this.setState({ step: step });
  }
  getAddNodeButton() {
    const { step } = this.props;
    const btn = <button className="btn" onClick={this.addNode.bind(this)}>Add Node</button>;
    if (step.stepNumber === 1) {
      return ((step.nodes.length < 1) && btn) || '';
    }
    else {
      return btn;
    }
  }
  getLanes(nodes) {
    let lanes = {};
    nodes.forEach(n => {
      n.lane = n.lane || 0;
      lanes[n.lane] = lanes[n.lane] || { nodes: [] };
      lanes[n.lane].nodes.push(n);
    });
    return lanes;
  }
  render() {
    let { step } = this.props;
    let lanes = this.getLanes(step.nodes);
    return (
      (
        <div className={ step.isDragging ? 'flow-step is-dragging':'flow-step'} id={"flow-step-"+step.stepNumber}>
          <div className="header">
            <h2>{step.name}</h2>
          </div>
          <div className="nodes">
          {Object.keys(lanes).map((lane) => { return (
              <div className="lane"
              >
              {
                lanes[lane].nodes.map((node,index) => {
                  return(
                      <div className="flow-node-wrapper">
                        <FlowNode node={node} editNode={this.editNode.bind(this)} />
                      </div>
                  );
                })
              }
            </div> 
            )}
          )}
          <div className={step.isDragging && (step.nodes && step.nodes.length > 1) ? 'new-lane is-dragging':'new-lane'} >
          </div> 
          </div> 
          </div>
      )
      // <a className = "btn" onClick = { this.editStep.bind(this) } > Edit Step </a> 
    );
  }
}
export default FlowStep;
