import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModificationField from "./GdprForm/ModificationField.js";
import Moment from 'react-moment';
import 'moment-timezone';
import { API } from 'aws-amplify';
import config from '../../config';


class CandidateModalPersonalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      modifications: [{ modification: null }],
      isLoading: false,
      requestId: null
    };
  }
  saveForm() {
    const { applicant, projectId } = this.props;
    const { modifications } = this.state;
    this.setState({ isLoading: true });

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/savePersonalDataRequest';
    let post = {
      body: {
        projectId: projectId,
        userId: applicant.ApplicationId,
        userType: 'candidate',
        request: modifications.map(mod => {
          let o = {
            modification: mod.modification.value,
            ...(mod.field && { field: mod.field.value }),
            ...(mod.editFieldValue && { editFieldValue: mod.editFieldValue })
          };
          return o;
        }),
      }
    };

    API.post(apiName, path, post).then(response => {
      let requestId = response.RequestId;
      this.setState({ requestId: requestId });
    });
  }
  toggleActionMenu() {
    this.setState({ showActions: !this.state.showActions });
  }

  addModification() {
    let { modifications } = this.state;

    modifications.push({ modification: null });

    this.setState({ modifications: modifications });
  }

  changeModification(index, newModification) {
    let { modifications } = this.state;

    modifications[index] = newModification;

    this.setState({ modifications: modifications });
  }

  deleteModification(index, newModification) {
    let { modifications } = this.state;

    modifications.splice(index, 1);

    this.setState({ modifications: modifications });
  }

  formIsValid() {
    let { modifications } = this.state;
    if (modifications.length === 0) {
      return false;
    }
    else {
      for (var i = 0; i < modifications.length; i++) {
        let mod = modifications[i];
        if (!mod.modification) {
          return false;
        }
        else if (mod.modification.value === 'deleteFieldValue' && !mod.field) {
          return false;
        }
        else if (mod.modification.value === 'editFieldValue') {
          if (!mod.field) {
            return false;
          }
          else if (!mod.editFieldValue || mod.editFieldValue.length === 0) {
            return false;
          }
        }
      }
      return true;
    }
  }

  render() {
    let { modifications, isLoading, requestId } = this.state;
    const { applicant, hidePersonalDataForm } = this.props;

    function getNormalCase(text) {
      var result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    let modificationOptions = [

      { label: "Ta bort värde i ett fält", value: "deleteFieldValue" },
      { label: "Ändra värde i ett fält", value: "editFieldValue" },
    ];

    const deleteCandidate = modifications.find((m) => m.modification && m.modification.value === 'deleteCandidate');
    if (modifications.length === 1) {
      modificationOptions.unshift({ label: "Ta bort ansökan", value: "deleteCandidate" });
    }


    const fieldsOptions = Object.keys(applicant.variables).sort((a, b) => a.localeCompare(b)).map(function(keyName, keyIndex) {
      return { label: getNormalCase(keyName), value: keyName };
    });

    return (

      <div className="modal modal--active modal--personal-data-form personal-data-form">
        <div className="card card--elevated modal__content modal__content--form">
          <button onClick={() => hidePersonalDataForm()} className="btn-link absolute-right pr-4 pt-3 text-muted">
            <FontAwesomeIcon icon={['fas','times']}/>
          </button>
    
          <div className="card__content-large">
          { requestId ? (<div className="text-center">
            <div>
              Din begäran har skickats in och har fått följande ärendenummer:
            </div>
            <h2>{requestId}</h2>
            Du kan följa alla inskickade ändringar på <Link to={`/personal-data-change-requests`}>denna sida</Link>
          </div>
          ):(
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row small-gutters justify-content-between text-center text-md-left">
                    <h2 class="mb-0">Förändringsbegäran</h2>    
                  </div>
                  <div className="spacing"></div>
                  <div>
                    Detta formulär möjliggör för er att ändra eller radera kandidat information
                    <ul>
                      <li>
                        Välj att antingen radera kandidat information eller att ändra specifika fält ( tex email/ namn/ telefon etc).
                      </li>                  
                      <li>
                        Ni kan begära flera ändringar i en ansökan genom att klicka på "Lägg till ändring"
                      </li>
                      <li>
                        När ni är klara med ändringarna, klicka på "Skicka ändringsbegäran"
                      </li>
                    </ul>
                  </div>
                  <div className="text-uppercase text-small font-weight-medium">
                    <div className={ isLoading ? "personal-data-form--loading": "" }>
                     { modifications.map((mod,index)=>{
                       return(
                        <ModificationField key={index} index={index} modificationOptions={modificationOptions} fieldsOptions={fieldsOptions} modification={mod} changeModification={this.changeModification.bind(this)} deleteModification={this.deleteModification.bind(this)} />
                       );
                     })}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {!deleteCandidate && <button className="button button--less-rounded button--x-small button--no-focus-outline"
                    disabled={isLoading} onClick={()=>this.addModification()}
                    >Lägg till ändring</button>
                  }
                  <button className={this.formIsValid() ? "button button--less-rounded button--x-small button--no-focus-outline float-right":  "button button--less-rounded button--x-small button--no-focus-outline float-right button--gray"}
                            disabled={isLoading || !this.formIsValid()}
                  onClick={()=>this.saveForm()}>Skicka ändringsbegäran</button>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    );
  }
}

export default CandidateModalPersonalData;
