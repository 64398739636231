import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';
import Moment from 'react-moment';
import 'moment-timezone';


class Gdpr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    if (this.props.project && !this.props.project.disableApplicationMetrics) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/getPersonalDataRequests';
      let myInit = {
        body: { projectId: this.props.project.ProjectId }
      };

      API.post(apiName, path, myInit).then(response => {
        let requests = response.data;
        this.setState({
          isLoaded: true,
          requests: requests,
        });
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ isLoaded: true });
    }
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  render() {
    const user = this.props.auth.user;
    const { project } = this.props;
    const { isLoaded, requests } = this.state;
    const tz = project.analytics ? project.analytics.timeZone : 'Europe/Stockholm';



    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableApplicationMetrics ? <DisabledMetrics /> : (
        <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>

           <div className="row no-gutters align-items-center">
             <div className="col-sm-auto">
               <h1 className="mb-0">Dataändringsbegäran</h1>
             </div>
             
             <div className="col-sm-auto">
                <div className="h-spacing h-spacing--medium"></div>
             </div>
           </div>
          <div className="spacing"></div>
           
          <div className="mw-paragraph">
            Här kan ni se status på ert ärende.<br/>
            När "Ändringsbegäran" är inskickad är status "Inskickad".<br/>
            När en ändring är klar flyttas ert ärende till "Avslutad".
          </div>
          <div className="spacing"></div>
          
          <div className="card card__table text-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th><span className="table__sortable">Request Id</span></th>
                  <th><span className="table__sortable">Status</span></th>
                  <th>
                    <span className="table__sortable table__sortable--active">
                      Skickad <i className="fas fa-caret-down"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                { requests.map( (request,index) => {
                  return (
                  <tr key={index}>
                  <td className="table__mobile-heading">
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Ärendenummer
                    </div>
                    {request.RequestId}
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Status
                    </div>
                    {request.currenStatus}
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Skickad
                    </div>
                    <Moment unix tz={tz} format="ddd, YYYY-MM-DD HH:mm:SS">
                      { request.created/1000 }
                    </Moment>
                  </td>
                </tr>)})
                }
              </tbody>
            </table>
            <div className="separator-h"></div>
            <div className="card__h-content">
              <div className="row justify-content-between align-items-center">
              </div>
            </div>
          </div>
        </div>
      </div>
      )));
  }
}
export default Gdpr;
