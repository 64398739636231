import React from 'react';
import DatePicker from "react-datepicker";
import ProfileMenu from "./ProfileMenu.js";
import "react-datepicker/dist/react-datepicker.css";


const Toolbar = (props) => {
    const { startDate, endDate, projectStartDate, setStartDate, setEndDate } = props;
    return (
      <div className="toolbar d-none d-xl-flex">
        {startDate && 
        <div className="toolbar__bar toolbar__date-picker c-spacing">
          <span>Välj datum:</span>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={projectStartDate}
            dateFormat="yyyy-MM-dd"
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="yyyy-MM-dd"
          />
        </div>}
        <div className="toolbar__bar c-spacing">
          <span>{props.project.name}</span>
          <div className="separator"></div>
          <ProfileMenu user={props.user} handleLogOut={props.handleLogOut} />
        </div>
      </div>
    );
};
export default Toolbar;
