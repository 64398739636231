import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toolbar from '../layout/Toolbar';
import LoadingBar from '../layout/LoadingBar';
import config from '../../config';
import { API } from 'aws-amplify';
import FacebookLogin from 'react-facebook-login';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';

class FacebookLoginOverview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showProfileMenu: false,
			isLoaded: false,
			isSaved: false,
			isSaving: false,
			notificationsStatus: {},
			fbLogin: null
		};
	}

	getData() {
		let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
		let path = '/getFacebookBotImplementations';
		let body = {
			body: { projectId: this.props.project.ProjectId }
		};

		API.post(apiName, path, body).then(response => {
			this.setState({
				isLoaded: true, botImplementations: response.botImplementations.map(bi => {
					bi.authStatus = {
						authorized: bi.fbPageAccessToken ? true : false,
						isAuthorizing: false,
						finishedAuthorization: false,
						failedAuthorizing: false
					}
					return bi;
				})
			});
		}).catch(error => {
			console.log("error", error);
		});
	}

	async componentDidMount() {
		const { project } = this.props;
		if (project) {
			this.getData();
		}
	}
	setToken(biid) {
		let { botImplementations, fbLogin } = this.state;
		let botImplementation = botImplementations.find(bi => bi.biid === biid);
		botImplementation.authStatus.authorized = false;
		botImplementation.authStatus.isAuthorizing = true;

		this.setState({ botImplementations: botImplementations });

		let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
		let path = '/setFacebookBotImplementation';
		let body = {
			body: {
				projectId: this.props.project.ProjectId,
				biid: biid,
				accessToken: fbLogin['accessToken']
			}
		};

		API.post(apiName, path, body).then(response => {
			botImplementation.authStatus.authorized = true;
			botImplementation.authStatus.isAuthorizing = false;
			botImplementation.authStatus.finishedAuthorization = true;
			botImplementation.authStatus.failedAuthorizing = false;
			console.log(response);
			this.setState({ botImplementations: botImplementations });
		}).catch(error => {
			botImplementation.authStatus.isAuthorizing = false;
			botImplementation.authStatus.finishedAuthorization = true;
			botImplementation.authStatus.failedAuthorizing = true;
			this.setState({ botImplementations: botImplementations });
			console.log("error", error);
		});
		return true;
	}

	checkboxHandle(setting) {
		let { notificationsStatus } = this.state;
		notificationsStatus[setting] = !notificationsStatus[setting];
		this.setState({ notificationsStatus: notificationsStatus });
	}
	responseFacebook = (response) => {
		console.log(response);
		if (response.accessToken) {
			this.setState({ fbLogin: response });
		}
	}
	render() {
		const user = this.props.auth.user;
		let { projects } = this.props;
		let { isLoaded, botImplementations, project, fbLogin } = this.state;

		return (!projects ? <div className="loading-wrapper"> <LoadingBar /></div> : (
			<div className="content mobile-menu-main">
				<div className="container">
					<div className="spacing"></div>
					{isLoaded && project &&
						<Toolbar handleLogOut={this.props.handleLogOut} user={user} project={project} />}
					<div className="spacing spacing--small"></div>
					<h1>
						<span className="mr-1">Facebook Login</span>
					</h1>
					{!fbLogin && botImplementations && botImplementations.length === 0 &&
						<div>
							Ni har för närvarande inga Facebook-chattbottar. Kontakta oss på Zmash för mer information!
						</div>
					}	
					{!fbLogin && botImplementations && botImplementations.length > 0 &&
						(<div>
							<div className="mw-paragraph">
								För att autentisera era sidor till chatbotten, klicka på knappen nedan. Se till siden att välja de sidor som boten ska ha tillgång till.
							</div>
							<FacebookLogin
								appId="2387354864898010"
								fields="name"
								scope="pages_show_list, pages_messaging, pages_read_engagement, pages_manage_metadata"
								callback={this.responseFacebook}
								icon="fa-facebook"
								textButton="Connect with Facebook"
								cssClass="button--facebook-connect"
							/></div>)}
					{fbLogin &&
						(
							<h2>
								Facebook är kopplad.
							</h2>
						)
					}
					{botImplementations && botImplementations.length > 0 && (
						<div>

							<div className="spacing"></div>
							<div className="mw-paragraph">
								När Facebook är påkopplad kan du välja ett av dina Facebook sidor nedan som du vill skapa en autentisering för.
            				</div>
							<div className="spacing"></div>

							<div>
								{isLoaded && botImplementations.map(bi => {
									return (
										<div className="card" key={bi.biid} style={{
											'margin': '1em 0',
											'padding': '1em'
										}}>
											<h3>{bi.name}</h3>
											<div>
												<span><strong>Page Id: </strong>{bi.fbPageId}</span>
												<div>
													{!bi.authStatus.isAuthorizing && bi.authStatus.authorized && (
														<div><FontAwesomeIcon icon={['fas', 'check']} className="text-green mr-2" />
															{bi.authStatus.finishedAuthorization ? 'Autentisering har skapats' : 'Autentisering finns'}
														</div>)}
													{bi.authStatus.isAuthorizing && (
														<div><FontAwesomeIcon icon={['fas', 'spinner']} className="spinner mr-2" /> Authorizing</div>)}
													{!bi.authStatus.isAuthorizing && !bi.authStatus.authorized && bi.authStatus.failedAuthorizing && (
														<div>Misslyckades med att skapa autentisering. Försök med att koppla på Facebook på nytt och med att skapa en ny autentisering</div>
													)}
												</div>
												<div>
													{!bi.authStatus.isAuthorizing && !bi.authStatus.authorized && fbLogin &&
														<button class="button button--small btn" onClick={() => this.setToken(bi.biid)}>Autentisera</button>
													}
													{!bi.authStatus.isAuthorizing && bi.authStatus.authorized && fbLogin &&
														<button class="button button--small btn my-2" onClick={() => this.setToken(bi.biid)}>Återautentisera </button>
													}
												</div>
											</div>
										</div>
									)
								})
								}
							</div>
						</div>
					)}
				</div>
			</div>
		));
	}
}
export default FacebookLoginOverview;
