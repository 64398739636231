import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';

const ProjectCard = (props) => {
  const {
    projectName,
    projectIcon,
    gradientStart,
    gradientEnd,
    project,
    setProject
  } = props;
  const cardStyle = {
    background: `linear-gradient(90deg, ${gradientStart} 0%, ${gradientEnd} 100%)`
  };
  return (
    <div className="col-lg-4">
      <div className="full-height-in-column">
        <div className="card card--fill" style={cardStyle}>
          <div className="metric__icon-container">
            <FontAwesomeIcon icon={projectIcon} className="metric__icon d-block"
              style={{color:gradientEnd}} fixedWidth/>
          </div>
          <div className="card__content project px4">
            <div>
              <b className="metric__title">{projectName}</b>
              <div className="metric__description">
                {"Start: "+ (project.startDate||"")}
              </div>
              <div className="mt-4 d-flex justify-content-right">
                <button className="button btn" 
                  style={{padding:'.5em 1em', margin: '0 '}} 
                  onClick={() => setProject(project)}>Öppna Dashboard</button>
              </div>
            </div>
          </div>
        </div>
        <div className="spacing spacing--small"></div>
      </div>
    </div>
  );
}

export default ProjectCard;
