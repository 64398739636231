import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommentsWindow from "./Comments/CommentsWindow.js";
import Moment from 'react-moment';
import 'moment-timezone';


class LeadsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false
    };
  }
  
  toggleActionMenu() {
    this.setState({ showActions: !this.state.showActions });
  }
  
  render() {
    function getNormalCase(text) {
      var result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    const { lead, tz, hideModal, projectId, showPersonalDataForm } = this.props;

    return (

      <div className="modal modal--active">
      <div className={"card card--elevated modal__content modal__content--x-large"}>
        <button onClick={() => hideModal()} className="btn-link absolute-right pr-4 pt-3 text-muted">
          <FontAwesomeIcon icon={['fas','times']}/>
        </button>

        <div className="card__content-large">
          <div className="row">
            <div className="col-md-3 with-separator">
              <div className="row small-gutters justify-content-between text-center text-md-left">
                <div className="col-md-auto">
                  <h2 className="mb-0">{lead.variables.firstName} {lead.variables.lastName}</h2>
                </div>
                
              </div>

              <div className="spacing"></div>

              <div className="text-uppercase text-small font-weight-medium">
                Lead
              </div>


              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={['far','clock']} className="text-muted mr-2"/>
                <span>
                  <Moment unix tz={tz} format="YYYY-MM-DD HH:mm:SS">
                      { lead.timestamp/1000 }
                  </Moment>
                </span>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={['fas','compass']} className="text-muted mr-2"/>
                <span>
                      { lead.pagePath }
                </span>
              </div>
              <div className="d-flex align-items-center my-3">
                <button className="button button--less-rounded button--x-small button--no-focus-outline"
                  onClick={()=>showPersonalDataForm(lead)}>Skapa ändring</button>
              </div>
              <div className="spacing"></div>

            </div>
            <div className="col-md-5 with-separator">
              <div className="spacing d-md-none"></div>
              <div className="text-uppercase text-small font-weight-medium">
                Fält
              </div>
              {Object.keys(lead.variables).sort((a, b) => a.localeCompare(b)).map(function(keyName, keyIndex) {
                return (
                <div key={keyName} className="tag tag--block mt-3">
                  <span className="font-weight-medium text-black">
                    {getNormalCase(keyName)}: 
                  </span>
                  <span> {lead.variables[keyName]}</span>
                </div>
                );
              })}
            </div>
            <div className="col-md-4">
              <div className="spacing d-md-none"></div>
              <div className="text-uppercase text-small font-weight-medium">
                Kommentarer
              </div>
              <CommentsWindow tz={tz} projectId={projectId} lead={lead}  />
            </div>  
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default LeadsModal;
