import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Doughnut } from 'react-chartjs-2';


function getChart(chartData, type) {
  const defaultColors = [
    '#f54b5a',
    '#f3b700',
    '#60cce6',
    '#49a9b9',
    '#f19f00',
    '#f67953',
    '#f9b29e',
    '#f39b9b',
    '#fdf2cc',
    '#d5d3e2',
    '#aacfde',
    '#a3b4d9'
  ];

  if (type === 'pie') {
    const options = { legend: { display: false } };
    if (chartData.datasets && chartData.datasets[0]) {
      chartData.datasets[0].backgroundColor = defaultColors;
    }

    return (
      <div> 
        <Doughnut data={chartData} options={options} />
      </div>
    );
  }
  else if (type === 'table') {
    const data = chartData.datasets[0] ? chartData.datasets[0].data : [];
    return (
      <div className="table-container">
        <table className="table table-striped table-insights">
          <thead>
            <tr>
              <th >
                <span href="#" className="table__not-sortable">Svar</span>
              </th>
              <th>
                <span href="#" className="table__not-sortable">Antal</span>
              </th>
            </tr>
          </thead>
          <tbody>
              {data.map(ans =>{
                return(
                  <tr key={ans.answerLabel}>
                    <td className="table__mobile-heading">
                      {ans.answerLabel}
                    </td>
                    <td>{ans.count}</td>
                  </tr>);
              })}
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'link') {
    return (
      <div>
        <div> 
          <strong className="text-primary">{chartData.percent}%</strong> • {chartData.uniqueClicks} people clicked
                            
        </div>
        <div className="spacing spacing--small"></div>
        <a href={chartData.buttonLink} className="link-block">
          <div className="row no-gutters align-items-center">
            <div className="col-auto text-center">
              <div className="link-block__icon">
                <i className="fas fa-link text-primary"></i>
              </div>
            </div>
            <div className="col line-height-normal">
              <strong className="text-primary">{chartData.buttonCta}</strong>
              <div className="text-small text-dark-gray">
                {chartData.buttonLink}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
  else return <div></div>;
}

class InsightsStep extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showStep: true
    };
  }

  toggleAccordion() {
    this.setState({ showStep: !this.state.showStep });
  }
  render() {
    const { showStep } = this.state;
    const { stepData } = this.props;

    return (
      <div className={showStep? 'accordion' : 'accordion--closed'}>
      <div className="row small-gutters">
        <div className="col-auto">
          <div className="accordion__number">{stepData.stepNumber}</div>
        </div>
        <div className="col">
          <div className="accordion__title">
            <div className="row small-gutters align-items-baseline">
              <div className="col-auto">
                <h2 className="text-black no-margin-bottom">{stepData.stepName}</h2>
              </div>
              <div className="col-auto">
                <button className="btn-link text-dark-gray" onClick={() => this.toggleAccordion()}>
                  { showStep ? 'Dölj resultat och mätvärden': 'Visa resultat och mätvärden' }
                  <FontAwesomeIcon icon={['fas',(showStep ? 'caret-up' : 'caret-down')]} fixedWidth/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row small-gutters accordion__content">
        <div className="col-auto d-none d-sm-block">
          <div className="accordion__line"></div>
        </div>
        <div className="col">
          <div className="spacing"></div>

          <div className="row">
            <div className="col-lg-4">
              <strong className="text-black">
                {this.props.isFiltered ? 'Filtrerad resultat': 'Konverteringsmätvärden'}
              </strong>
              <div className="spacing spacing--small"></div>

              <div className="card card--fill">
                <div className="d-flex align-items-center no-gutters card__content metric">
                  <div className="text-center metric__stats">
                    <b className="metric__title text-primary">{stepData.percentage}%</b>
                  </div>
                  <div className="separator separator--large"></div>
                  <div className="line-height-normal">
                    <b className="metric__title text-primary text-small no-margin-bottom">{stepData.users} användare</b>
                    <div className="text-small">
                      Har tagit sig till detta steg.
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacing d-lg-none"></div>
            </div>
            <div className="spacing d-lg-none"></div>
          </div>

          <div className="spacing"></div>
          {stepData.researchPoints && 
          <div className="insights__block">
              <strong className="text-black">
                {this.props.isFiltered ? 'Filtrerad resultat': 'Resultat'}
              </strong>
              <div className="spacing spacing--small"></div>
              
             <div className="row">
              { stepData.researchPoints.map((researchPoint,index) => {
                return (
                <div className={researchPoint.type === "table" ? "col-lg-6 insights__card" :"col-lg-4 insights__card"} key={index}>
                    <div className="card card--fill card--fill-top">
                      <div className="card__content">
                        <strong className="text-black">
                          {researchPoint.title}
                        </strong>
                          {researchPoint.chartData && getChart(researchPoint.chartData, researchPoint.type)}
                      </div>
                    </div>
                    <div className="spacing d-lg-none"></div>
                </div>);
              })}
              </div>
            </div>
          }
          <div className="spacing"></div>
        </div>
      </div>
    </div>
    );
  }
}
export default InsightsStep;
