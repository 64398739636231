import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from "react-csv";


class CandidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false
    };
  }
  toggleActionMenu() {
    this.setState({ showActions: !this.state.showActions });
  }


  render() {
    function getNormalCase(text) {
      var result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    }

    function getHeaders(data) {
      // return { label: getNormalCase(v), key: `variables.${v}` };
      let allHeaders = [];

      data.forEach(app => {
        Object.keys(app.variables).forEach(v => {
          allHeaders.push(v);
        });
      });

      allHeaders = allHeaders.length > 0 ? [...new Set(allHeaders)] : [];
      allHeaders = allHeaders.map(h => {
        return { label: getNormalCase(h), key: `variables.${h}` };
      });


      allHeaders.push({
        label: "Job",
        key: "job.title"
      });
      allHeaders.push({
        label: "Job Location",
        key: "job.city"
      });

      return allHeaders;
    }

    let csvData = this.props.data.filter((app) => { return app.selected; })
    return (
      <div className="selection-bar">
        <div className="selection-bar__inner">
          <div className="row align-items-center no-gutters">
            <div className="col-sm-auto">
              <i className="far fa-check fa-fw"></i> {this.props.totalSelected} kandidater valda
            </div>
            <div className="col-sm-auto">
              <div className="separator"></div>
            </div>
            <div className="col-sm-auto">
              <div className={this.state.showActions ? 'dropdown dropdown--active': 'dropdown'}>
                <button onClick={() => this.toggleActionMenu()} className="dropdown__trigger selection-bar__button">
                  Välj alternativ <FontAwesomeIcon icon={['fas','caret-up']}/>
                </button>
                <div className="dropdown__popup dropdown__popup--above dropdown__popup--align-right">
                  <div className="dropdown__hatch">
                      <FontAwesomeIcon icon={['fas','caret-down']}/>
                  </div>
                  <div className="dropdown__links">
                  <CSVLink data={csvData} headers={getHeaders(this.props.data)} filename={"zmash-candidates-"+ (new Date()).toUTCString()+".csv"} className="text-dark-gray font-weight-medium no-underline d-block">
                    <FontAwesomeIcon icon={['fas','cloud-download-alt']} className="text-muted mr-1"/>
                    Exportera till CSV</CSVLink>;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CandidateModal;

// <a href="javascript:void(0)"  onClick={() => {this.props.exportSelected()}} className="text-dark-gray font-weight-medium no-underline d-block">
// <FontAwesomeIcon icon={['fas','cloud-download-alt']} className="text-muted mr-1"/>
//   Export to CSV
// </a>
