import React from 'react';
import LoadingBar from '../layout/LoadingBar';

function SaveScreen(props) {
  return (
    <div className="save-screen-wrapper">
        <div className="save-screen-modal">
        <div> <LoadingBar background="bg-white" /></div>
            Saving...
        </div>
    </div>
  );
}

export default SaveScreen;
