import React from 'react';
import { API } from 'aws-amplify';
import config from '../../../config';
import CommentsList from './CommentsList';
import CommentsComposer from './CommentsComposer';

class CommentsWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  handleClickOutside = evt => {
    this.props.hideModal();
  };

  saveComment(comment) {
    const { projectId, applicant } = this.props;
    this.setState({ isLoading: true });

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/saveCandidateComment';
    let post = {
      body: {
        projectId: projectId,
        biid: applicant.biid,
        email: applicant.Email,
        applicationId: applicant.ApplicationId,
        comment: comment
      }
    };

    API.post(apiName, path, post).then(response => {
      applicant.comments = response.comments;
      this.setState({ isLoading: false });
    });
  }
  
  removeComment(index) {
    const { projectId, applicant } = this.props;
    this.setState({ isLoading: true });

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/deleteCandidateComment';
    let post = {
      body: {
        projectId: projectId,
        biid: applicant.biid,
        email: applicant.Email,
        applicationId: applicant.ApplicationId,
        index: index.toString()
      }
    };

    API.post(apiName, path, post).then(response => {
      applicant.comments = response.comments;
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isLoading } = this.state;
    const { applicant, tz } = this.props;
    return (
      <div className="comment__window">
        <CommentsList tz={tz} 
          comments={applicant.comments}
          removeComment={this.removeComment.bind(this)}
          isLoading={isLoading}
        />
        {(applicant.comments ? applicant.comments.length < 10 : true) &&
          <CommentsComposer 
            saveComment={this.saveComment.bind(this)} isLoading={isLoading}/>
        }
      </div>
    );
  }
}

export default CommentsWindow;
