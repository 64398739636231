import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import MobileMenuBar from './components/layout/MobileMenuBar';
import MobileMenu from './components/layout/MobileMenu';
import Sidebar from './components/layout/Sidebar';
import PrivateComponent from './PrivateComponent';


class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoggedIn: this.props.auth.isAuthenticated,
      showMobileMenu: false
    }
  }
  toggleMobileMenu() {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };
  renderPrivateRoute(renderProps) {
    const { component: Component, projects, ...rest } = this.props;
    let { showMobileMenu } = this.state;
    return this.props.auth.isAuthenticated ? (this.props.noInterface ?
      <PrivateComponent component={Component} {...rest} {...renderProps} auth={rest.auth} projects={projects} /> :

      <div className={this.state.showMobileMenu ? "wrapper mobile-menu-visible" : "wrapper"}>
              <MobileMenuBar toggleMobileMenu={this.toggleMobileMenu.bind(this)}/>
              <MobileMenu 
                showMobileMenu={showMobileMenu}
                auth={rest.auth}
                toggleMobileMenu={this.toggleMobileMenu.bind(this)}
                projects={projects}
                project={this.props.project}
              />
              <Sidebar path={rest.path} isAdmin={rest.auth.isAdmin} projects={projects} project={this.props.project} />
              <PrivateComponent component={Component} {...rest} {...renderProps} auth={rest.auth} projects={projects} />
            </div>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: renderProps.location } }} />
    )
  }
  render() {
    const { ...rest } = this.props;
    return (
      <Route
        {...rest}
        component={this.renderPrivateRoute.bind(this)}
      />
    );
  }
};

export default PrivateRoute;
