import React from 'react';
import InnerButtonTextArea from "../InnerButtonTextArea";

class PersonalDataConsent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action,
         actionIndex: this.props.actionIndex,
         node: this.props.node
      };
   }

   handleAnswerChange(event, index) {
      let { action } = this.props;
      action.content.answers[index].answerContent = event.target.value;
      this.setState({ action: action });
   }

   render() {
      let { action } = this.props;

      return (
         <div className="action personalData">
            <div className="message">
               GDPR Consent
            </div>
            <div className="answers">
               {action.content.answers.map((answer, index)=>{
                  return (
                  <div key={index}>
                   <InnerButtonTextArea answerContent={answer.answerContent} changeHandler={this.handleAnswerChange.bind(this)} index={0}/>
                  </div>);
               })}
            </div>
         </div>
      );
   }
}
export default PersonalDataConsent;
