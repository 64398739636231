import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MobileMenuBar = (props) => {
    return (
      <div className="d-xl-none mobile-menu-bar">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <Link to="/"><img src="/images/logo.svg" alt="Zmash" /></Link>
            </div>
            <div className="col-auto">
              <button className="mobile-menu-bar__button" onClick={()=> {props.toggleMobileMenu()}} id="open-menu-button">
                <FontAwesomeIcon icon={['fas','bars']} fixedWidth  /> 
              </button>
              <button className="mobile-menu-bar__button" onClick={()=> {props.toggleMobileMenu()}} id="close-menu-button" >
                <FontAwesomeIcon icon={['fas','times']} fixedWidth  /> 
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};
export default MobileMenuBar;
