import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';

const BrandMetricCard = (props) => {
  const {
    metricValue,
    metricTitle,
    metricDescription,
    metricIcon,
    gradientStart,
    gradientEnd
  } = props;
  const cardStyle = {
    background: `linear-gradient(90deg, ${gradientStart} 0%, ${gradientEnd} 100%)`
  };
  return (
    <div className="col-sm">
      <div className="full-height-in-column">
        <div className="card card--fill metric metric--fill brand-metric" style={cardStyle}>
          <div className="card__content text-center">
            <div className="spacing"></div>
            <div className="icon-aligner">
              <div className="icon-bubble">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={metricIcon} 
                    style={{color:gradientStart}}
                    className="metric__icon" fixedWidth
                  />
                </div>
              </div>
            </div>
            <div className="spacing spacing--small-half"></div>
            <div className="metric__title">
              {metricValue}
            </div>

            <div className="spacing"></div>
            <div className="separator-h" style={{background:'#fff'}}></div>
            <div className="spacing"></div>

            <h2 className="text-white">{metricTitle}</h2>
            <div className="text-small text-white">
              {metricDescription}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandMetricCard;
