import React from 'react';
import CommentsWindow from "./CommentsWindow.js";
import onClickOutside from "react-onclickoutside";
import 'moment-timezone';

class CommentsModal extends React.Component {
  handleClickOutside = evt => {
    this.props.hideModal();
  };
  render() {
    const { applicant, tz, projectId } = this.props;

    return (
      <div className="comments-modal comments-modal--active card card--elevated">
        <CommentsWindow tz={tz} applicant={applicant} projectId={projectId}/>
      </div>
    );
  }
}

export default onClickOutside(CommentsModal);
