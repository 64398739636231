import React from "react";
import Highlighter from "react-highlight-words";

class ChatTextArea extends React.Component {
  constructor() {
    super();
  }

  render() {
    let { messageContent } = this.props;
    return (
      <div className="message">
        <div className="text-area-wrapper">
          <textarea name="messageContent" 
              value={messageContent}
              onChange={this.props.changeHandler}>
          </textarea>
        </div>
        <div className="hidden-textarea">
          {messageContent.split('\n').map((item, key) => {
            return <span className="span-line" key={key}>
                <Highlighter
                    highlightClassName="variable-highlight"
                    searchWords={[/\{\{\{.*\}\}\}/]}
                    textToHighlight={item}/>
            </span>
          })}
       </div>
    </div>
    );
  }
}

export default ChatTextArea;
