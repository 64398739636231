import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from "react-onclickoutside";


class FormDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
    };
  }
  toggleDrowDown() {
    const newState = !this.state.showDropDown;
    this.setState({ showDropDown: newState });
  }
  onChange(value) {
    this.props.onChange(value);
    this.setState({ showDropDown: false });
  }
  handleClickOutside = evt => {
    this.setState({ showDropDown: false });
  };
  render() {
    const { showDropDown } = this.state;
    const { optionsList, selectedOption, ctaLabel } = this.props;
    return (
      <span className={ showDropDown? 'dropdown dropdown--active': 'dropdown' } >
          { selectedOption  &&
          <button onClick={() => this.toggleDrowDown()} className="dropdown__trigger text-black font-weight-medium no-underline">
            {selectedOption.label }
            <FontAwesomeIcon className="text-muted" icon={['fas','caret-down']} fixedWidth  />
          </button>}
          { !selectedOption && 
          <button onClick={() => this.toggleDrowDown()} className="dropdown__trigger text-black font-weight-medium no-underline">
              {ctaLabel}
            <FontAwesomeIcon className="text-muted" icon={['fas','caret-down']} fixedWidth  />
          </button>}

          <div className="dropdown__popup dropdown__popup--align-center">
            <div className="dropdown__hatch">
                <FontAwesomeIcon className="text-muted" icon={['fas','caret-up']} fixedWidth  />
            </div>

            <div className="dropdown__links dropdown--colored-options">
              {optionsList && optionsList.map(option =>{
                  return(
                  <button key={option.label} className={ (selectedOption && (option.value === selectedOption.value)) ? "font-weight-medium no-underline d-block active-in-list":"font-weight-medium no-underline d-block"} onClick={()=> this.onChange(option)}>
                    {option.label}
                  </button>);
              })}
            </div>
          </div>
        </span>
    )
  };
}
export default onClickOutside(FormDropDown);
