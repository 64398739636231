import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from "react-onclickoutside";


class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false
    };
  }

  toggleProfileMenu() {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  }
  handleClickOutside = evt => {
    if (this.state.showProfileMenu === true) {
      this.setState({ showProfileMenu: false });
    }
  };
  render() {
    const { user, handleLogOut } = this.props;
    return (
      <div className={this.state.showProfileMenu ? "dropdown dropdown--active": "dropdown"}>
      <button href="#" onClick={this.toggleProfileMenu.bind(this)} className="dropdown__trigger text-dark-gray no-underline">
        {`${user.attributes.name} ${user.attributes.family_name}`}
        <FontAwesomeIcon icon="caret-down" fixedWidth/>
      </button>

      <div className="dropdown__popup dropdown__popup--align-right">
        <div className="dropdown__hatch">
          <i className="fas fa-caret-up"></i>
        </div>

        <div className="dropdown__links">
          <Link to="/projects" className="text-dark-gray font-weight-medium no-underline d-block">
            <FontAwesomeIcon icon="cog" className="text-muted mr-1" fixedWidth/>
            Projekt
          </Link>
          <Link to="/facebook-login" className="text-dark-gray font-weight-medium no-underline d-block">
            <FontAwesomeIcon icon={['fab','facebook']} className="text-muted mr-1" fixedWidth/>
            Facebook Login
          </Link>
          <button className="text-dark-gray font-weight-medium no-underline d-block" onClick={handleLogOut}>
            <FontAwesomeIcon icon="sign-out-alt" className="text-muted mr-1" fixedWidth/>
            Logga ut
          </button>
        </div>
      </div>
    </div>);
  }
}
export default onClickOutside(ProfileMenu);
