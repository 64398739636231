import React from 'react';
import Xarrow from "react-xarrows";
import ChatTextArea from "../ChatTextArea";

class Question extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         actionIndex: this.props.actionIndex,
         node: this.props.node
      };
   }

   getRelations(answer) {
      if (answer.nextNodeId) {
         return [{
            targetId: answer.nextNodeId,
            targetAnchor: 'left',
            sourceAnchor: 'right',
            style: {
               strokeColor: '#6656c2',
               strokeWidth: 2,
               endShape: { arrow: { arrowLength: 5 } },
            },
         }];
      }
      else {
         return [];
      }
   }
   
   handleChange(event) {
      let { action } = this.props;
      action.content.messageContent = event.target.value;
      this.setState({ action: action });
   }
   
   render() {
      let { action } = this.props;

      return (
         <div className="action question collect">
            <div className="variable">
               {action.content.variable}
            </div>
            <ChatTextArea messageContent={action.content.messageContent} changeHandler={this.handleChange.bind(this)}/>
            <div className="answers">
               {action.content.answers.map((answer, index)=>{
               
                  const uniqueId = (this.props.node.id +'_' + this.props.actionIndex +'_'+index);
                  return (
                  <div key={uniqueId} className="answer">
                     <div className="answer-field">
                        <span>{answer.answerContent}</span>
                        <div className="out-anchor-wrapper">
                           { answer.nextNodeId  &&
                              <div
                              className="out-anchor" 
                              data-next-node-id={answer.nextNodeId}
                              id={uniqueId}
                              >
                                <Xarrow start={uniqueId} 
                                  startAnchor="right" 
                                  end={'node_'+answer.nextNodeId}  
                                  endAnchor="left"
                                  strokeWidth={2}
                                  color={"#6656c2"}
                                  advanced={{
                                    passProps:{
                                      SVGcanvas: {
                                        "className":"xarrow"
                                      } 
                                    } 
                                  }} />
                              </div>
                           }
                        </div>
                     </div>
                  </div>);
               })}
            </div>
         </div>
      );
   }
}
export default Question;
