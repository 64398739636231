import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormDropDown from "./FormDropDown.js";


class ModificationField extends React.Component {
  constructor(props) {
    super(props);
  }
  
  modificationChange(newValue){
    let {index, modification, changeModification} = this.props;
    modification.modification = newValue;
    
    changeModification(index,modification);
  }
  fieldChange(newValue){
    let {index, modification, changeModification} = this.props;
    modification.field = newValue;
    
    changeModification(index,modification);
  }
  handleEditFieldChange(event){
    let {index, modification, changeModification} = this.props;
    const newValue = event.target.value.slice(0, 255);
    this.setState({ inputValue: newValue });
    modification.editFieldValue = newValue;
    
    changeModification(index,modification);
  }
  render() {
    let { modification, modificationOptions, fieldsOptions, deleteModification, index} = this.props;
    
    return (
      <div className="modification-field">
        <span> Välj förändring: <FormDropDown onChange={this.modificationChange.bind(this)} optionsList={modificationOptions} selectedOption={modification.modification} ctaLabel="Förändring"/></span>
        {modification.modification && modification.modification.value !== 'deleteLead' &&
        (
          <span> - Välj fält: <FormDropDown onChange={this.fieldChange.bind(this)} optionsList={fieldsOptions} selectedOption={modification.field} ctaLabel="Fält"/></span>
        )}
        
        {modification.modification && modification.modification.value === 'editFieldValue' &&
        (
        <div>Nytt värde: <input 
            type="text" 
            id="addComment" 
            value={modification.editedValue}
            placeholder="Nytt värde för fältet"
            onChange={this.handleEditFieldChange.bind(this)}
            maxLength="500"
         /></div>
        )}
        <button 
          className="button btn-link button--x-small button button--pointer button--padding-zero button--outlined button--no-focus-outline remove-btn"
          onClick={()=> deleteModification(index)}
        >
          <FontAwesomeIcon icon={['fas','times']}/>
        </button>
                
      </div>
    );
  }
}
export default ModificationField;
