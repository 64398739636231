import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LoadingBar from '../layout/LoadingBar';
import CandidateFunnel from './NlpCandidateFunnel';
import ClickFunnel from './NlpClickFunnel';
import DisabledMetrics from '../home/DisabledMetrics';
import MetricCard from '../layout/MetricCard';


class NlpFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };

  }

  async componentDidMount() {
    const { project, startDate, endDate } = this.props;
    if (project && startDate && endDate) {
      this.getData(startDate, endDate);
    }
  }

  getData(startDate, endDate) {
    const { project, formatDate } = this.props;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/nlpOverview';
    let myInit = {
      body: { projectId: project.ProjectId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
    };

    API.post(apiName, path, myInit).then(response => {
      this.setState({ isLoaded: true, stats: response.data.stats, funnel: response.data.funnel });
    }).catch(error => {
      console.log("error", error);
    });
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  setStartDate(date) {
    this.getData(date, this.props.endDate);
    this.setState({ isLoaded: false });
    this.props.setStartDate(date);
  }
  setEndDate(date) {
    this.getData(this.props.startDate, date);
    this.setState({ isLoaded: false });
    this.props.setEndDate(date);
  }

  render() {
    const user = this.props.auth.user;
    const { project, handleLogOut, projectStartDate, defaultStartDate, startDate, endDate } = this.props;

    const { stats, funnel, isLoaded } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableAiMetrics ? <DisabledMetrics /> : (
        <div className="content mobile-menu-main">
        <div className="container">
          <div className="spacing"></div>
            <Toolbar handleLogOut={handleLogOut} user={user} project={project} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} setStartDate={this.setStartDate.bind(this)}  setEndDate={this.setEndDate.bind(this)} />

          <div className="spacing spacing--small"></div>
          <h1>
            <span className="mr-1">Navigeringsflöde</span>
          </h1>

          <div className="mw-paragraph">
            Ta reda på hur effektiv den artificiella intelligensen är på att hjälpa kandidater till jobb som passar deras bakgrund. Grafen nedan visar hur användare förflyttar sig genom kandidatresan och hela vägen till att bli en potentiell eller faktisk kandidat. 
          </div>
          <div className="spacing spacing--medium"></div>
          <h2>ChatBott-statistik</h2>
          <div className="row small-gutters">
            <MetricCard metricValue={stats.sessions} metricTitle="Sessioner"
              metricDescription="Antal gånger AI har blivit triggat av användare"
              metricIcon={['far','eye']} 
              gradientStart="#ff4d5d" gradientEnd="#ff99a1"
            />
            
            <MetricCard metricValue={funnel.conversations} metricTitle="Konversationer"
              metricDescription="Antal gånger användare har interagerat minst en gång med AI"
              metricIcon={['far','comments']} gradientStart="#2d398b" gradientEnd="#00aeef"
            />

            <MetricCard metricValue={stats.jobClicksTotal} metricTitle="Klick"
              metricDescription="Antal klick på föreslagna jobb"
              metricIcon={['far','hand-pointer']} gradientStart="#ef4136" gradientEnd="#fbaf40"
            />

            {!this.props.project.disableApplicationMetrics &&
            
            <MetricCard metricValue={stats.applicationsTotal} metricTitle="Ansökningar"
              metricDescription="Totala antalet ansökningar till positionen"
              metricIcon={['far','file-alt']} gradientStart="#0fc373" gradientEnd="#15f18f"
            />
            }
          </div>
          
          <div className="spacing spacing--small"></div>
          <div className="spacing"></div>

          <h2>Kandidatresa</h2>
          <div className="side-scroll">
          {this.props.project.disableApplicationMetrics ?
            <ClickFunnel funnel={funnel} getPercentOf={this.getPercentOf} />:
            <CandidateFunnel funnel={funnel} getPercentOf={this.getPercentOf} />
          }
          </div>
        </div>
      </div>
      )));
  }
}
export default NlpFlow;
