import React from "react";

function FormErrors(props) {
  if (
    props.formerrors &&
    (props.formerrors.blankfield || props.formerrors.passwordmatch)
  ) {
    return (
      <div>
        <div className="error container help is-danger">
          <div className="row justify-content-center">
            {props.formerrors.passwordmatch
              ? "Password value does not match confirm password value"
              : ""}
          </div>
          <div className="row justify-content-center help is-danger">
            {props.formerrors.blankfield ? "All fields are required" : ""}
          </div>
        </div>

        <div className="spacing spacing--small"></div>

      </div>
    );
  } else if (props.apierrors) {
    return (
      <div>
        <div className="error container help is-danger">
          <div className="row justify-content-center">{props.apierrors}</div>
        </div>

        <div className="spacing spacing--small"></div>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito) {
    return (
      <div>
        <div className="error container help is-danger">
          <div className="row justify-content-center">
            {props.formerrors.cognito.message}
          </div>
        </div>

        <div className="spacing spacing--small"></div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default FormErrors;