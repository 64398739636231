import React from "react";

class InnerButtonTextArea extends React.Component {
  constructor() {
    super();
  }

  render() {
    let { answerContent, changeHandler, index } = this.props;
    return (
      <div className="inner-button">
        <div>
          <input name="answerContent" className="answer-content-input"
              maxLength="20"
              size={(answerContent && answerContent.length> 0 ? answerContent.length: 1)}
              value={answerContent}
              onChange={(event) => changeHandler(event, index)}>
          </input>
       </div>
    </div>
    );
  }
}

export default InnerButtonTextArea;
