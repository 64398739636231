import React from 'react';


function BarRect(props) {
  return <rect {...props} />;
}

class LoadingBar extends React.Component {
  constructor(props) {
    super(props);
    let bars = [
      { x: "0", y: "0", width: "5", height: "17", rx: "2.5" },
      { x: "11", y: "0", width: "5", height: "17", rx: "2.5" },
      { x: "22", y: "0", width: "5", height: "17", rx: "2.5" },
      { x: "33", y: "0", width: "5", height: "17", rx: "2.5" },
      { x: "44", y: "0", width: "5", height: "17", rx: "2.5" }
    ];
    this.state = {
      step: 0,
      bars: bars
    }
  }
  getWaveHeight = (min, max) => {
    let step = this.state.step;

    step += Math.PI / 2;

    this.setState({ step: step });

    return (Math.sin(step) * (max - min)) / 2 + min / 2 + max / 2;
  };

  animateLoader = () => {
    const maxHeight = 17;
    const bars = this.state.bars;
    if (bars) {
      bars.forEach(bar => {
        const height = this.getWaveHeight(5, maxHeight);
        bar.height = height + "px";
        bar.y = maxHeight / 2 - height / 2;
      });
    }

  };

  componentDidMount() {
    let animationInterval = setInterval(this.animateLoader, 300);
    this.setState({ animationInterval: animationInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.animationInterval);
  }
  getClass(bg){
    return `${bg} px-4 py-3 rounded-lg rounded-bl-none text-black mb-1 leading-snug zmashbot-animated-message-bot`;
  }

  render() {
    const background = this.props.background || 'bg-light-gray';
    return (
      <div className="loading-wrapper">
        <div
          className={this.getClass(background)}
        >
          <svg
            width="49"
            height="17"
            viewBox="0 0 49 17"
            className="fill-primary"
            id="zmashbot-loading"
            xmlns="http://www.w3.org/2000/svg"
          >
          {
            this.state.bars.map((bar, i) => {
                return <BarRect key={i} x={bar.x} y={bar.y} height={bar.height} width={bar.width} rx={bar.rx} />; 
            })
          }
          </svg>
        </div>
      </div>
    )
  }
}
export default LoadingBar;
