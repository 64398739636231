import React from 'react';
import { Redirect } from "react-router-dom";
import { Auth } from 'aws-amplify';

class PrivateComponent extends React.Component {
  constructor(props) {
    super(props);
    let sixMonthsAgo = new Date();
    const project = this.props.project || (this.props.projects && this.props.projects.length === 1 ? this.props.projects[0] : null);
    
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    // const projectStartDate = sixMonthsAgo;
    let projectStartDate = sixMonthsAgo;
    let defaultStartDate = projectStartDate.getTime() > sixMonthsAgo.getTime() ? projectStartDate : sixMonthsAgo;
    if (project && project.startDate) {
      projectStartDate = new Date(project.startDate);
      defaultStartDate = projectStartDate.getTime() > sixMonthsAgo.getTime() ? projectStartDate : sixMonthsAgo;
    }
    const endDate = new Date();
    this.state = {
      isPrivate: true,
      project: project,
      projectStartDate: projectStartDate,
      defaultStartDate: defaultStartDate,
      startDate: defaultStartDate,
      endDate: endDate
    };
  }
  setProject(project) {
    this.props.setProject(project);
    this.props.history.push("/overview");
  }
  handleLogOut = async event => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    }
    catch (error) {
      console.log(error.message);
    }
  }
  setStartDate(date) {
    this.setState({ startDate: date });
  }
  setEndDate(date) {
    this.setState({ endDate: date });
  }

  formatDate(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  }

  render() {
    const { component: Component } = this.props;
    if (this.props.location.pathname === '/new-user') {
      return <Component {...this.props} />;
    }
    if (this.state.project || this.props.location.pathname === '/projects') {
      const { projectStartDate, defaultStartDate, startDate, endDate } = this.state;

      return (
        <Component {...this.props} project={this.state.project} setProject={this.setProject.bind(this)} handleLogOut={this.handleLogOut.bind(this)} setStartDate={this.setStartDate.bind(this)} setEndDate={this.setEndDate.bind(this)}
          formatDate={this.formatDate.bind(this)} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} />
      );
    }
    else {
      console.log('redirecting');
      return (
        <Redirect to={{ pathname: '/projects', state: { from: this.props.location } }} setProject={this.setProject.bind(this)}/>
      );
    }
  }
}
export default PrivateComponent;
