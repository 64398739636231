import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import CandidatesFilterTools from './CandidatesFilterTools.js';
import CandidateModal from "./CandidateModal.js";
import CandidateModalPersonalDataRequest from "./CandidateModalPersonalDataRequest.js";
import CommentsModal from "./Comments/CommentsModal.js";
import SelectionBar from "./SelectionBar.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';
import Moment from 'react-moment';
import 'moment-timezone';


class CandidatesOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
      modal: null,
      personalDataModal: null,
      commentsModal: null,
      totalSelected: 0,
      allChecked: false,
      filters: []
    };
  }

  async componentDidMount() {
    if (this.props.project && !this.props.project.disableApplicationMetrics) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/getCandidates';
      let myInit = {
        body: { projectId: this.props.project.ProjectId }
      };

      API.post(apiName, path, myInit).then(response => {
        let applicants = response.data.map(app => {
          app.selected = false;
          return app;
        });
        this.setState({
          isLoaded: true,
          applicants: applicants,
          applicantsList: applicants,
          jobs: response.jobs,
          filterVariables: response.filterFields,
          hasFilterChanged: false,
          isFiltered: false
        });
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ isLoaded: true });
    }
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  setFilters(filters) {
    this.setState({ filters: filters });
  }

  clearFilter() {
    this.setState({ filters: [], isFiltered: false }, this.applyFilter());
  }

  filterIsValid(filters) {
    return filters.filter(f => {
      return !f.isValues || (f.isValues ? f.isValues.length === 0 : true);
    }).length === 0;
  }

  applyFilter() {
    const { filters, applicants } = this.state;
    if (this.filterIsValid(filters)) {

      const applicantsList = applicants.filter(app => {

        var fieldMatches = filters.map(ff => {
          const isValues = ff.isValues.map(f => f.value);
          if (ff.name === 'Job') {

            return isValues.indexOf(app.job.JobId) > -1;
          }
          else if (ff.name === 'City') {
            // return app.city === ff.value;
            return isValues.indexOf(app.job.city) > -1;
          }
          else {
            var field = app.variables[ff.name];

            return field ? isValues.indexOf(field) > -1 : false;
          }
        });
        return fieldMatches.indexOf(false) === -1;
      });
      this.setState({ applicantsList: applicantsList, isFiltered: filters.length > 0, hasFilterChanged: false });
    }
  }
  updateFilters(filters) {
    this.setState({ filters: filters });
  }

  filterHasChanged(val) {
    this.setState({ hasFilterChanged: val });
  }

  showModal(applicant) {
    this.setState({ modal: applicant });
  }

  showPersonalDataForm(applicant) {
    this.setState({ personalDataModal: applicant });
  }
  showCommentsModal(applicant) {
    this.hideCommentsModal();
    applicant.showCommentsModal = true;
    this.setState({ commentsModal: applicant });
  }

  hideCommentsModal() {
    let { applicantsList } = this.state;
    applicantsList.map(a => a.showCommentsModal = false);
    this.setState({ applicantsList: applicantsList });
  }

  hideModal() {
    this.setState({ modal: null });
  }

  hidePersonalDataForm() {
    this.setState({ personalDataModal: null });
  }

  onChangeHandler(e) {
    var val = e.target.value;
    var newArray = val ? this.state.jobs.filter((d) => {
      return d.title.indexOf(e.target.value) !== -1;
    }) : this.state.jobs;
    this.setState({
      jobList: newArray
    });
  }
  handleCheck(index) {
    let { applicantsList } = this.state;
    let app = applicantsList[index];
    app.selected = !app.selected;
    const totalSelected = applicantsList.filter(app => app.selected).length;
    this.setState({ applicantsList: applicantsList, totalSelected: totalSelected });
  }
  handleCheckAll() {
    let { applicantsList, allChecked } = this.state;
    allChecked = !allChecked;
    applicantsList = applicantsList.map(app => {
      app.selected = allChecked;
      return app;
    });
    const totalSelected = applicantsList.filter(app => app.selected).length;
    this.setState({ allChecked: allChecked, applicantsList: applicantsList, totalSelected: totalSelected });
  }
  getBotType(type) {
    switch (type) {
      case 'fb':
        return 'Facebook';
      case 'js':
        return 'Website';
      default:
        return 'Website';
    }
  }
  render() {
    const user = this.props.auth.user;
    const { project } = this.props;
    const { isLoaded, modal, totalSelected, applicantsList, filters, personalDataModal } = this.state;
    const tz = project.analytics ? project.analytics.timeZone : 'Europe/Stockholm';
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableApplicationMetrics ? <DisabledMetrics /> : (
        <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>

           <div className="row no-gutters align-items-center">
             <div className="col-sm-auto">
               <h1 className="mb-0">Kandidater</h1>
             </div>
             <div className="col-sm-auto">
               <div className="h-spacing h-spacing--medium"></div>
             </div>
           </div>
          <div className="spacing"></div>
          
          <CandidatesFilterTools 
            filters={filters} 
            fields={this.state.filterVariables} 
            setFilters={this.setFilters.bind(this)} 
            hasFilterChanged={this.state.hasFilterChanged} 
            applyFilter={this.applyFilter.bind(this)}
            clearFilter={this.clearFilter.bind(this)}
            updateFilters={this.updateFilters.bind(this)}
            filterHasChanged={this.filterHasChanged.bind(this)}
            isFiltered={this.state.isFiltered}
            />
          
          <div className="spacing"></div>
          <div className="d-lg-none">
            <div className="d-flex align-items-center">
              <div className="checkbox">
                <input id="check-all" type="checkbox" onChange={() => this.handleCheckAll()} checked={this.state.allChecked} />
                <span><FontAwesomeIcon icon={['fas','check']}/></span>
              </div>

              <label className="mb-0 ml-2" htmlFor="check-all">Select all</label>
            </div>
            <div className="spacing spacing--small"></div>
          </div>
          
          <div className="card card__table text-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="table__checkbox">
                    <div className="checkbox">
                      <input id="check-all" type="checkbox" onChange={() => this.handleCheckAll()} checked={this.state.allChecked} />
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>
                  </th>
                  <th><span className="table__sortable">Namn</span></th>
                  <th><span className="table__sortable">Jobb</span></th>
                  <th><span className="table__sortable">Botkälla</span></th>
                  <th><span className="table__sortable">Plats</span></th>
                  <th>
                    <span className="table__sortable table__sortable--active">
                      Skickad <i className="fas fa-caret-down"></i>
                    </span>
                  </th>
                  <th>Kommentar</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { applicantsList.map( (application,index) => {
                  const hasComments = (application.comments && application.comments.length > 0);
                  return (
                  <tr key={index}>
                  <td className="table__checkbox d-none d-lg-table-cell">
                    <div className="checkbox">
                      <input type="checkbox" checked={application.selected} onChange={() => this.handleCheck(index)}/>
                      
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>
                  </td>
                  <td className="table__mobile-heading">
                    <div className="checkbox d-lg-none mr-3">
                      <input type="checkbox" checked={application.selected} onChange={() => this.handleCheck(index)}/>
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>

                    <button onClick={() => this.showModal(application)} className="btn-link text-black font-weight-medium no-underline">
                      {application.variables.firstName} {application.variables.lastName}
                    </button>
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Jobb
                    </div>
                    {application.job.title}
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Botkälla
                    </div>
                      { this.getBotType(application.botImplementationType) }
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Plats
                    </div>
                      { application.pagePath }
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Skickad
                    </div>
                    <Moment unix tz={tz} format="ddd, YYYY-MM-DD HH:mm:SS">
                      { application.timestamp/1000 }
                    </Moment>
                  </td>
                  <td className="comment d-none d-lg-table-cell">
                    {hasComments && (
                      <button onClick={() => this.showCommentsModal(application)} 
                        className={application.showCommentsModal ? "btn-link comment-highlight" : "btn-link text-dark-gray"}>
                        <FontAwesomeIcon icon={['fas','check']}/>
                      </button>
                    )}
                    {!hasComments && (
                      <button onClick={() => this.showCommentsModal(application)}
                        className={application.showCommentsModal ? "btn-link comment-highlight" : "btn-link text-dark-gray show-on-hover"}>
                        <FontAwesomeIcon className="" icon={['fas','plus']} />
                      </button>
                    )}
                    
                    {application.showCommentsModal &&
                    <CommentsModal applicant={application} 
                      hideModal={this.hideCommentsModal.bind(this)} 
                      tz={tz}
                      projectId={project.ProjectId}
                    />
                      
                    }
                  </td>
                  <td className="text-right d-none d-lg-table-cell">
                    <button onClick={() => this.showModal(application)} className="btn-link text-dark-gray">
                      <FontAwesomeIcon icon={['fas','chevron-right']}/>
                    </button>
                  </td>
                </tr>)})
                }
              </tbody>
            </table>
            <div className="separator-h"></div>
            <div className="card__h-content">
              <div className="row justify-content-between align-items-center">
              </div>
            </div>
          </div>
        </div>
        {modal && <CandidateModal applicant={modal} tz={tz} projectId={project.ProjectId} hideModal={this.hideModal.bind(this)} showPersonalDataForm={this.showPersonalDataForm.bind(this)} />}
        {personalDataModal && <CandidateModalPersonalDataRequest applicant={modal} tz={tz} projectId={project.ProjectId} hidePersonalDataForm={this.hidePersonalDataForm.bind(this)}/>}
        {totalSelected > 0 && <SelectionBar totalSelected={totalSelected} data={applicantsList} />}
      </div>
      )));
  }
}
// {commentsModal && <CommentsModal applicant={commentsModal} hideModal={this.hideCommentsModal.bind(this)}/>}
export default CandidatesOverview;
