import React from 'react';
import Highlighter from "react-highlight-words";
import ChatTextArea from "../ChatTextArea";

class Message extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   handleChange(event) {
      let { action } = this.props;
      action.content.messageContent = event.target.value;
      this.setState({ action: action });
   }
   render() {
      let { action } = this.props;
      //    <Highlighter
      //     highlightClassName="variable-highlight"
      //     searchWords={[/\{\{\{.*\}\}\}/]}
      //    //  autoEscape={true}
      //     textToHighlight={action.content.messageContent}
      //  />

      return (
         <div className="action">
            <ChatTextArea messageContent={action.content.messageContent} changeHandler={this.handleChange.bind(this)}/>
         </div>
      );
   }
}
export default Message;
