import React from 'react';

class SaveApplication extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action,
         actionIndex: this.props.actionIndex,
         node: this.props.node
      };
   }


   render() {
      let { action } = this.props;

      return (
         <div className="action saveApplication">
            <div className="message">
               SaveApplication
            </div>
         </div>
      );
   }
}
export default SaveApplication;
