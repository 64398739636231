import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LeadsFilterTools from './LeadsFilterTools.js';
import LeadsModal from "./LeadsModal.js";
import LeadsShowHide from "./LeadsShowHide.js";
import LeadsModalPersonalDataRequest from "./LeadsModalPersonalDataRequest.js";
import CommentsModal from "./Comments/CommentsModal.js";
import SelectionBar from "./SelectionBar.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';
import Moment from 'react-moment';
import 'moment-timezone';


class LeadsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
      modal: null,
      personalDataModal: null,
      commentsModal: null,
      totalSelected: 0,
      allChecked: false,
      filters: []
    };
  }

  async componentDidMount() {
    if (this.props.project && !this.props.project.disableleadMetrics) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/getLeads';
      let myInit = {
        body: { projectId: this.props.project.ProjectId }
      };

      API.post(apiName, path, myInit).then(response => {
        let leads = response.data.map(app => {
          app.selected = false;
          return app;
        });
        this.setState({
          isLoaded: true,
          leads: leads,
          leadsList: this.filterLeadsList(leads, false),
          filterVariables: response.filterFields,
          hasFilterChanged: false,
          isFiltered: false,
          showHidden: false
        });
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ isLoaded: true });
    }
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  // setFilters(filters) {
  //   this.setState({ filters: filters });
  // }

  // clearFilter() {
  //   this.setState({ filters: [], isFiltered: false }, this.applyFilter());
  // }

  // filterIsValid(filters) {
  //   return filters.filter(f => {
  //     return !f.isValues || (f.isValues ? f.isValues.length === 0 : true);
  //   }).length === 0;
  // }

  // applyFilter() {
  //   const { filters, leads } = this.state;
  //   if (this.filterIsValid(filters)) {

  //     const leadsList = leads.filter(app => {

  //       var fieldMatches = filters.map(ff => {
  //         const isValues = ff.isValues.map(f => f.value);
  //         if (ff.name === 'Job') {

  //           return isValues.indexOf(app.job.JobId) > -1;
  //         }
  //         else if (ff.name === 'City') {
  //           // return app.city === ff.value;
  //           return isValues.indexOf(app.job.city) > -1;
  //         }
  //         else {
  //           var field = app.variables[ff.name];

  //           return field ? isValues.indexOf(field) > -1 : false;
  //         }
  //       });
  //       return fieldMatches.indexOf(false) === -1;
  //     });
  //     this.setState({ leadsList: leadsList, isFiltered: filters.length > 0, hasFilterChanged: false });
  //   }
  // }
  // applyFilter() {
  //   const { filters, leads } = this.state;
  //   if (this.filterIsValid(filters)) {

  //     const leadsList = leads.filter(app => {

  //       var fieldMatches = filters.map(ff => {
  //         const isValues = ff.isValues.map(f => f.value);
  //         if (ff.name === 'Job') {

  //           return isValues.indexOf(app.job.JobId) > -1;
  //         }
  //         else if (ff.name === 'City') {
  //           // return app.city === ff.value;
  //           return isValues.indexOf(app.job.city) > -1;
  //         }
  //         else {
  //           var field = app.variables[ff.name];

  //           return field ? isValues.indexOf(field) > -1 : false;
  //         }
  //       });
  //       return fieldMatches.indexOf(false) === -1;
  //     });
  //     this.setState({ leadsList: leadsList, isFiltered: filters.length > 0, hasFilterChanged: false });
  //   }
  // }
  // updateFilters(filters) {
  //   this.setState({ filters: filters });
  // }

  // filterHasChanged(val) {
  //   this.setState({ hasFilterChanged: val });
  // }¨
  filterLeadsList(list, show) {
    
    const leadsList = show ? list : list.filter(l => {
      if (!l.displayProperties) {
        return true;
      }
      else {
        return !l.displayProperties.hideInDashboard;
      }
    });
    return leadsList;
  }
  refilterLeadsList(){
    const { leads } = this.state;
    const leadsList = this.filterLeadsList(leads, this.state.showHidden);
    this.setState({ leadsList: leadsList })
  }
  toggleShowHidden(toggle) {
    const { leads } = this.state;
    const show = toggle || !this.state.showHidden;
    const leadsList = this.filterLeadsList(leads, show);

    this.setState({ leadsList: leadsList, showHidden: !this.state.showHidden })
  }
  showModal(lead) {
    this.setState({ modal: lead });
  }

  showPersonalDataForm(lead) {
    this.setState({ personalDataModal: lead });
  }
  showCommentsModal(lead) {
    this.hideCommentsModal();
    lead.showCommentsModal = true;
    this.setState({ commentsModal: lead });
  }

  hideCommentsModal() {
    let { leadsList } = this.state;
    leadsList.map(a => a.showCommentsModal = false);
    this.setState({ leadsList: leadsList });
  }

  hideModal() {
    this.setState({ modal: null });
  }

  hidePersonalDataForm() {
    this.setState({ personalDataModal: null });
  }

  onChangeHandler(e) {
    var val = e.target.value;
    var newArray = val ? this.state.jobs.filter((d) => {
      return d.title.indexOf(e.target.value) !== -1;
    }) : this.state.jobs;
    this.setState({
      jobList: newArray
    });
  }
  handleCheck(index) {
    let { leadsList } = this.state;
    let app = leadsList[index];
    app.selected = !app.selected;
    const totalSelected = leadsList.filter(app => app.selected).length;
    this.setState({ leadsList: leadsList, totalSelected: totalSelected });
  }
  handleCheckAll() {
    let { leadsList, allChecked } = this.state;
    allChecked = !allChecked;
    leadsList = leadsList.map(app => {
      app.selected = allChecked;
      return app;
    });
    const totalSelected = leadsList.filter(app => app.selected).length;
    this.setState({ allChecked: allChecked, leadsList: leadsList, totalSelected: totalSelected });
  }
  getBotType(type) {
    switch (type) {
      case 'fb':
        return 'Facebook';
      case 'js':
        return 'Website';
      default:
        return 'Website';
    }
  }

  // <LeadsFilterTools 
  //   filters={filters} 
  //   fields={this.state.filterVariables} 
  //   setFilters={this.setFilters.bind(this)} 
  //   hasFilterChanged={this.state.hasFilterChanged} 
  //   applyFilter={this.applyFilter.bind(this)}
  //   clearFilter={this.clearFilter.bind(this)}
  //   updateFilters={this.updateFilters.bind(this)}
  //   filterHasChanged={this.filterHasChanged.bind(this)}
  //   isFiltered={this.state.isFiltered}
  //   />
  render() {
    const user = this.props.auth.user;
    const { project } = this.props;
    const { isLoaded, modal, totalSelected, leadsList, filters, personalDataModal, showHidden } = this.state;
    const tz = project.analytics ? project.analytics.timeZone : 'Europe/Stockholm';
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableleadMetrics ? <DisabledMetrics /> : (
        <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>
          <div className="spacing spacing--small"></div>
          <h1>
            <span className="mr-1">Leads</span>
          </h1>
          <div className="mw-paragraph">
           Översikten ger dig en vy över er chattbot-statistik oavsett vart boten är placerad eller vad för funktion den har. Datan nedan visar en summering av chattbotresultaten för det här projektet.
          </div>
          <div className="spacing"></div>
          
          <div className="spacing"></div>
          <div className="d-lg-none">
            <div className="d-flex align-items-center">
              <div className="checkbox">
                <input id="check-all" type="checkbox" onChange={() => this.handleCheckAll()} checked={this.state.allChecked} />
                <span><FontAwesomeIcon icon={['fas','check']}/></span>
              </div>

              <label className="mb-0 ml-2" htmlFor="check-all">Select all</label>
            </div>
            <div className="spacing spacing--small"></div>
          </div>
          <div className="toolbar d-xl-flex">
            <div className="toolbar__bar c-spacing">
              <div className="checkbox mr-3">
                  <input id="show-hidden"type="checkbox" checked={showHidden} onChange={() => this.toggleShowHidden()} />
                  <span><FontAwesomeIcon icon={['fas','check']}/></span>
              </div>
              <label className="mb-0 ml-2" htmlFor="show-hidden">Visa Dolda</label>
            </div>
          </div>
          <div className="spacing spacing--small"></div>
          <div className="card card__table text-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="table__checkbox">
                    <div className="checkbox">
                      <input id="check-all" type="checkbox" onChange={() => this.handleCheckAll()} checked={this.state.allChecked} />
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>
                  </th>
                  <th><span className="table__sortable">Namn</span></th>
                  <th><span className="table__sortable">Botkälla</span></th>
                  <th><span className="table__sortable">Plats</span></th>
                  <th>
                    <span className="table__sortable table__sortable--active">
                      Skickad <i className="fas fa-caret-down"></i>
                    </span>
                  </th>
                  <th>Kommentar</th>
                  <th>Dölj</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { leadsList.map( (lead,index) => {
                  const hasComments = (lead.comments && lead.comments.length > 0);
                  
                  const isHidden = (lead.displayProperties && lead.displayProperties.hideInDashboard)
                  return (
                  <tr key={index}>
                  <td className="table__checkbox d-none d-lg-table-cell">
                    <div className="checkbox">
                      <input type="checkbox" checked={lead.selected} onChange={() => this.handleCheck(index)}/>
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>
                  </td>
                  <td className="table__mobile-heading">
                    <div className="checkbox d-lg-none mr-3">
                      <input type="checkbox" checked={lead.selected} onChange={() => this.handleCheck(index)} />
                      <span><FontAwesomeIcon icon={['fas','check']}/></span>
                    </div>

                    <button onClick={() => this.showModal(lead)} className="btn-link text-black font-weight-medium no-underline">
                      {lead.variables.firstName} {lead.variables.lastName}
                    </button>
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Botkälla
                    </div>
                      { this.getBotType(lead.botImplementationType) }
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Plats
                    </div>
                      { lead.pagePath }
                  </td>
                  <td>
                    <div className="d-lg-none text-uppercase font-weight-bold text-smaller">
                      Skickad
                    </div>
                    <Moment unix tz={tz} format="ddd, YYYY-MM-DD HH:mm:SS">
                      { lead.timestamp/1000 }
                    </Moment>
                  </td>
                  <td className="comment d-none d-lg-table-cell">
                    {hasComments && (
                      <button onClick={() => this.showCommentsModal(lead)} 
                        className={lead.showCommentsModal ? "btn-link comment-highlight" : "btn-link text-dark-gray"}>
                        <FontAwesomeIcon icon={['fas','check']}/>
                      </button>
                    )}
                    {!hasComments && (
                      <button onClick={() => this.showCommentsModal(lead)}
                        className={lead.showCommentsModal ? "btn-link comment-highlight" : "btn-link text-dark-gray show-on-hover"}>
                        <FontAwesomeIcon className="" icon={['fas','plus']} />
                      </button>
                    )}
                    
                    {lead.showCommentsModal &&
                    <CommentsModal lead={lead} 
                      hideModal={this.hideCommentsModal.bind(this)} 
                      tz={tz}
                      projectId={project.ProjectId}
                    />
                      
                    }
                  </td>
                  <td className="hideLead d-none d-lg-table-cell">
                    <LeadsShowHide lead={lead} projectId={project.ProjectId} refilterLeadsList={this.refilterLeadsList.bind(this)}/>
                  </td>
                  <td className="text-right d-none d-lg-table-cell">
                    <button onClick={() => this.showModal(lead)} className="btn-link text-dark-gray">
                      <FontAwesomeIcon icon={['fas','chevron-right']}/>
                    </button>
                  </td>
                </tr>)})
                }
              </tbody>
            </table>
            <div className="separator-h"></div>
            <div className="card__h-content">
              <div className="row justify-content-between align-items-center">
              </div>
            </div>
          </div>
        </div>
        {modal && <LeadsModal lead={modal} tz={tz} projectId={project.ProjectId} hideModal={this.hideModal.bind(this)} showPersonalDataForm={this.showPersonalDataForm.bind(this)} />}
        {
          personalDataModal && <LeadsModalPersonalDataRequest lead={modal} tz={tz} projectId={project.ProjectId} hidePersonalDataForm={this.hidePersonalDataForm.bind(this)}/>
        }
        {totalSelected > 0 && <SelectionBar totalSelected={totalSelected} data={leadsList} />}
      </div>
      )));
  }
}
export default LeadsOverview;
