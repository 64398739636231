import React from 'react';
import SaveScreen from './SaveScreen';
import FlowStep from './FlowStep';
import './floweditor.scss';
import qs from "query-string";
import { withRouter } from "react-router-dom";
import config from "../../config.json";
import { Auth, API } from 'aws-amplify';

class FlowEditor extends React.Component {
  constructor(props) {
    super(props);
    const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const flowVersionId = this.props.match.params.flowVersionId;
    const key = queryString.k;
    const projectId = queryString.p;
    this.state = {
      auth: {
        FlowVersionId: flowVersionId,
        key: key,
        projectId: projectId,
        steps: []
      },
      isLoaded: false,
      failed: false,
    };
    const apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;


    this.api = apiName;
  }

  componentDidMount() {

    if (this.props.match.params && this.props.match.params.flowVersionId) {
      const { FlowVersionId, key, projectId } = this.state.auth;
      let path = '/getFlowVersionForEdit';
      let init = {
        body: {
          projectId: projectId,
          flowVersionId: FlowVersionId,
          key: key
        }
      };

      API.post(this.api, path, init).then(response => {
        if (response && response.flowVersion) {
          var flow = response.flowVersion;
          if (flow.FlowVersionId) {
            flow.steps = flow.steps || [];
            flow.steps.map(s => {
              s.uid = Date.now() + '-' + s.stepNumber;
              return s;
            });
            flow.variables = flow.variables || [];

            // if (flow.variables.length > 0) {

            if (flow.variables.indexOf('email') === -1) {
              flow.variables.push('email');
            }
            if (flow.variables.indexOf('firstName') === -1) {
              flow.variables.push('firstName');
            }
            if (flow.variables.indexOf('lastName') === -1) {
              flow.variables.push('lastName');
            }
            if (flow.variables.indexOf('linkedIn') === -1) {
              flow.variables.push('linkedIn');
            }
            if (flow.variables.indexOf('phone') === -1) {
              flow.variables.push('phone');
            }
            // }
            this.setState({ flow: flow, isLoaded: true });
          }
        }
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ failed: true });
    }
  }

  // Node Functions
  editNode(editNode) {
    this.setState({ editNode: editNode });
  }


  getNodeById(flow, nodeId) {
    const nodes = flow.steps.map(s => { return s.nodes }).reduce((n, nn) => { return n.concat(nn) });
    return nodes.find(node => { return node.id.toString() === nodeId.toString() });
  }

  getActionRow(action, index) {
    switch (action.actionType) {
      case 'question':
        return action.content.answers[index];
      case 'goToNode':
        return action;
      default:
        return action.content.answers[index];
    }
  }

  getComponentEditorClass() {
    let className = 'component-editor';
    className = (this.state.editNode || this.state.editStep) ? 'component-editor show' : 'component-editor';
    return className;
  }
  getCanvasClass() {
    let className = 'canvas';
    className = (this.state.editNode || this.state.editStep) ? 'canvas move' : 'canvas';
    return className;
  }

  saveFlow() {
    this.setState({ isSaving: true }, () => {
      const { FlowVersionId, key, projectId  } = this.state.auth;

      let path = '/saveClientFlow';

      let init = {
        body: {
          projectId: projectId,
          flowVersionId: FlowVersionId,
          key: key,
          steps: this.state.flow.steps
        }
      };

      API.post(this.api, path, init).then(response => {
        // if (response && response === "Saved") {
          this.props.history.push('/flows');
        // }
      }).catch(error => {
        console.log("error", error);
      });
    });
  }
  toggleSettings() {
    this.setState({ showSettings: !this.state.showSettings });
  }
  addVariable(event) {
    let { flow } = this.state;
    flow.variables.push("New Variable");
    this.setState({ flow: flow });
  }
  removeVariable(event) {
    let { flow } = this.state;
    let i = event.target.getAttribute('data-index');
    flow.variables.splice(Number(i), 1);
    this.setState({ flow: flow });
  }

  handleVariableChange(event) {
    let { flow } = this.state;
    flow.variables[event.target.name] = event.target.value;
    this.setState({ flow: flow });
  }
  isEditable(variable) {
    switch (variable) {
      case 'email':
        return false;
      case 'lastName':
        return false;
      case 'firstName':
        return false;
      case 'linkedIn':
        return false;
      case 'phone':
        return false;
      default:
        return true;
    }
  }
  logFlow() {
    console.log(this.state.flow);
  }

  refCallback = element => {
    if (element) {
      this.setState({
        svgW: element.getBoundingClientRect().width.toFixed(0),
        svgH: element.getBoundingClientRect().height.toFixed(0),
      });
    }
  };

  render() {
    let { flow } = this.state;
    let s = `.arrow-container > svg {
width:${this.state.svgW+'px'}!important;
height:${this.state.svgH+'px'}!important;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  height: 100%;
}
#root>div {
  height: 100%;
}

`;

    return (
      this.state.isLoaded &&

      <div className="flowEditor">
        {this.state.isSaving && (
          <SaveScreen />
        )
        }
            <div className={this.getCanvasClass()}>
                <header>
                  <div className="flow-type">
                    {flow.flow.flowType} flow
                  </div>
                  <div className="flow-title">
                      {flow.name}
                  </div>
                </header>
                <div className="step-wrapper" ref={this.refCallback}>
                    {
                      flow.steps.map((step,index) => {
                        return (
                               <FlowStep step={step}
                                key={step.uid}
                                editNode={this.editNode.bind(this)}
                                >
                                </FlowStep>
                           );
                         }
                      )
                    }
                </div>
            </div>
            <div className="floating-menu">
              <div id="floating-btns">
                <div className="floating-btn save-btn" onClick={this.saveFlow.bind(this)}>
                  <i className="material-icons">save_alt</i>
                  <span>Save flow</span>
                </div>
                <style>
                  {s}
                </style>
              </div>
            </div>
          </div>
    );
  }
}
export default withRouter(FlowEditor);
