import React, { Component } from 'react';
import FormErrors from "./FormErrors";
import Validate from "./FormValidation";
import { Auth } from "aws-amplify";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errors: {
        cognito: null,
        blankfield: false
      }
    };
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].className = 'login';
  }
  componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
  }
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.props.auth.setLogin(user, () => {
          this.props.history.push("/new-user");
        });
      }
      else {
        this.props.auth.setLogin(user);
        this.props.history.push("/overview");
      }

    }
    catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <div>
        <div className="login-form">
        <img className="zmash-logo" src="images/logo-filled.svg" alt="Zmash Logo" />
          <h1>Log in</h1>
          <p className="leading">
            Welcome back! login to access the Zmash Dashboard. <br />
            Did you <a href="/forgot-password">forget your password?</a>
          </p>
          
          <section className="form">
    
            <form onSubmit={this.handleSubmit}>
              <div className="field">
                  <input 
                    className="input" 
                    type="text"
                    id="username"
                    aria-describedby="usernameHelp"
                    placeholder="Email"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
              </div>
              <div className="field">
                  <input 
                    className="input" 
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onInputChange}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-lock"></i>
                  </span>
              </div>
              <FormErrors formerrors={this.state.errors} />
              <div className="field">
                  <button className="button is-success">
                    Log In
                  </button>
              </div>
            </form>
          </section>
          <section className="footer-links">
          <div>
            2017-2020 All Rights Reserverd
          </div>
          <div>
            <a href="https://zmash.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy and terms</a>
          </div>
          </section>
        </div>
        <div className="hero">
         <div className="hero-card">
          <h3>Zmash presents:</h3>
          <h2>The Rise of Artifical Intelligence in HR</h2>
          <p>In a new report, we analyze the status quo of recruitment and employer branding and explain how Zmashbot, through AI can help any brand be better at creating data-driven strategies.
          </p>
          <a href="/downloads/Zmash_-_the_rise_of_ai_in_hr.pdf" target="_blank" className="download-btn">Download now</a>
         </div>
        </div>
      </div>
    );
  }
}

export default Login;
