import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LoadingBar from '../layout/LoadingBar';
import InsightsStep from './components/InsightsStep';
import InsightsHeader from './components/InsightsHeader';
import InsightsFilterTools from './components/InsightsFilterTools';

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false,
      selectedFlow: null,
      filters: []
    };
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }
  processApiResponse(response) {
    response.data.variables = response.data.variables || [];
    response.data.jobs = response.data.jobs || [];
    if (this.props.hasApplicationBot) {

      let jobFilter = {
        name: 'Job',
        possibleValues: response.data.jobs.map(j => {
          return { label: j.title, value: j.JobId };
        })
      };

      response.data.variables.unshift(jobFilter);
    }

    return response;
  }
  async componentDidMount() {
    const { project, startDate, endDate, formatDate } = this.props;
    if (project) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/insights';
      let init = {
        body: { projectId: project.ProjectId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
      };

      API.post(apiName, path, init).then(response => {
        response = this.processApiResponse(response);

        this.setState({
          isLoaded: true,
          flows: response.data.flows,
          filterVariables: response.data.variables,
          selectedFlow: response.data.flows[0] || {
            flowVersionId: 0,
            name: null
          },
          steps: response.data.steps,
          hasFilterChanged: false,
          isFiltered: false
        });
      }).catch(error => {
        console.log("error", error);
      });
    }

  }

  setFilters(filters) {
    this.setState({ filters: filters });
  }
  selectFlow(flow) {
    // this.setState({ selectedFlow: flow });
    this.refreshData(flow, this.props.startDate, this.props.endDate);
  }
  clearFilter() {
    this.setState({ filters: [], isLoaded: false }, () => {
      this.refreshData(null, this.props.startDate, this.props.endDate);
    });
  }
  updateFilters(filters) {
    this.setState({ filters: filters });
  }

  filterHasChanged(val) {
    this.setState({ hasFilterChanged: val });
  }
  refreshData(flowVersionId, startDate, endDate) {
    const { project, formatDate } = this.props;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/insights';
    let isFiltered = this.state.filters.length > 0;
    let { selectedFlow } = this.state;
    const newFlow = flowVersionId ? true : false;
    flowVersionId = flowVersionId || selectedFlow.FlowVersionId;
    let post = {
      body: {
        projectId: project.ProjectId,
        flowVersionId: flowVersionId,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        ...(isFiltered && !newFlow ? { filters: this.state.filters } : {})
      }
    };


    API.post(apiName, path, post).then(response => {
      response = this.processApiResponse(response);
      const flows = response.data.flows;
      let flow = flows.find(f => f.FlowVersionId === flowVersionId);
      this.setState({
        isLoaded: true,
        flows: flows,
        selectedFlow: flow,
        filterVariables: response.data.variables,
        steps: response.data.steps,
        hasFilterChanged: false,
        isFiltered: isFiltered
      });
    });
  }
  applyFilter() {
    this.setState({ isLoaded: false });
    this.refreshData(null, this.props.startDate, this.props.endDate);
  }

  setStartDate(date) {
    this.refreshData(null, date, this.props.endDate);
    this.setState({ isLoaded: false });
    this.props.setStartDate(date);
  }
  setEndDate(date) {
    this.refreshData(null, this.props.startDate, date);
    this.setState({ isLoaded: false });
    this.props.setEndDate(date);
  }

  render() {
    const user = this.props.auth.user;
    const { project, handleLogOut, projectStartDate, defaultStartDate, startDate, endDate } = this.props;
    const { isLoaded, flows, selectedFlow, filters, steps } = this.state;

    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      <div className="content mobile-menu-main insights">
        <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={handleLogOut} user={user} project={project} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} setStartDate={this.setStartDate.bind(this)}  setEndDate={this.setEndDate.bind(this)} />
          <div className="spacing spacing--small"></div>

          <InsightsHeader flows={flows} selectedFlow={selectedFlow} selectFlow={this.selectFlow.bind(this)} />
          <div className="spacing spacing--small"></div>
          <div className="mw-paragraph">
            Få mer information om era användare och deras kandidatresa. För varje flöde kan ni se hur många som tog sig till de olika stegen i konversationerna och deras svar samt vart de droppade av. Använd filtrerings-funktionen för att se resultaten baserat på de olika fälten i flödet. 
          </div>
          <div className="spacing spacing--small"></div>
          <InsightsFilterTools 
            filters={filters} 
            variables={this.state.filterVariables} 
            setFilters={this.setFilters.bind(this)} 
            hasFilterChanged={this.state.hasFilterChanged} 
            applyFilter={this.applyFilter.bind(this)}
            clearFilter={this.clearFilter.bind(this)}
            updateFilters={this.updateFilters.bind(this)}
            filterHasChanged={this.filterHasChanged.bind(this)}
            isFiltered={this.state.isFiltered}
            />
          <div id="insight-steps">
          { steps && steps.map(stepData => {
              return <InsightsStep key={stepData.stepNumber} stepData={stepData} isFiltered={this.state.isFiltered}/>;
            })
          }
          </div>
       </div>
     </div>
    ));
  }
}
export default Insights;
