import React from 'react';

class SaveLead extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action,
         actionIndex: this.props.actionIndex,
         node: this.props.node
      };
   }


   render() {
      let { action } = this.props;

      return (
         <div className="action saveLead">
            <div className="message">
               Save Lead
            </div>
            <div className="variables">
               {action.content.variables.map((variable, index)=>{
                  return (
                  <div key={index} className="variable">
                     <span>{variable.name}</span>
                  </div>);
               })}
            </div>
         </div>
      );
   }
}
export default SaveLead;
