import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LoadingBar from '../layout/LoadingBar';
import BrandMetricCard from '../layout/BrandMetricCard';

const getComparisonBox = (newRes, metricsComparison) => {
  let orig = metricsComparison === 'leads' ? 0.045 : 0.1757;
  var diff = ((newRes - orig) / orig);
  if (diff >= 0) {
    return (
      <div className="card__even-content-large">
        <h3>Ni har ett bra resultat! <span role="img" aria-label="Party Popper">🎉</span></h3>
        Bra jobbat! Ni konverterar era potentiella kandidater och er engagement rate är 
        <span className="font-weight-medium text-green"> {(diff * 100).toFixed(0)}% högre jämfört med andra företag</span>.
      </div>);
  }
  else {
    return (
      <div className="card__even-content-large">
        <h3>Ni kan få ett bättre resultat!</h3>
        Ni är nästan där! Er engagement rate är 
        <span className="font-weight-medium text-red"> {(diff * 100).toFixed(0)}% lägre jämfört med andra företag</span>.
      </div>
    );
  }
};

const getRecommendationBox = (newRes, metricsComparison) => {
  let orig = metricsComparison === 'leads' ? 0.045 : 0.1757;
  var diff = ((newRes - orig) / orig);
  if (diff >= 0) {
    return (
      <div className="card__even-content-large text-center">
        <div className="font-weight-medium">
          Skapa en <span className="text-accent">Awareness-kampanj</span> för att få fler användare att känna till ert erbjudande. Är du intresserad? Prata med oss!
        </div>
        <div className="spacing spacing--small"></div>
        <a href="mailto:?subject=Ny Kampanj&body=Hej, vi är intresserade av att starta en ny Awareness kampanj!" className="button button--small button--accent">
          Prata med oss!
        </a>
      </div>
    );
  }
  else {
    return (
      <div className="card__even-content-large text-center">
        <div className="font-weight-medium">
          Skapa en <span className="text-accent">Social Media Marketing-kampanj</span> för att göra fler användare medvetna om er och interagera mer. Låter det intressant? Prata med oss så berättar vi mer! 
        </div>
        <div className="spacing spacing--small"></div>
        <a href="mailto:?subject=Ny Kampanj&body=Hej, vi är intresserade av att starta en ny Social Media Marketing kampanj!" className="button button--small button--accent">
          Prata med oss!
        </a>
      </div>
    );
  }
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };

  }
  async componentDidMount() {
    if (this.props.project) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/dashboardOverview';
      let myInit = {
        body: { projectId: this.props.project.ProjectId }
      };

      API.post(apiName, path, myInit).then(response => {
        this.setState({ isLoaded: true, stats: response.stats, jobs: response.jobs });
      }).catch(error => {
        console.log("error", error);
      });
    }
    else {
      this.setState({ isLoaded: true });
    }
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  render() {
    const user = this.props.auth.user;
    const { stats, isLoaded } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>

          <div className="spacing spacing--small"></div>
          <h1>
            <span className="mr-1">Engagement Rate</span>
          </h1>

          <div className="mw-paragraph">
            Er engagement rate visar hur chattboten engagerar era användare. Den representerar det procentuella antalet användare som har sett chattboten och valt att interagera med den minst en gång.
          </div>
          <div className="spacing spacing--extra-large"></div>
          <h2>Hur det beräknas</h2>
          <div className="row">
            <BrandMetricCard metricValue={stats.conversations} metricTitle="Konversationer"
              metricDescription="Antal gånger användare har interagerat minst en gång med chattboten"
              metricIcon={['far','comment']} 
              gradientStart="#2d398b" gradientEnd="#00aeef"
            />

            <div className="col-sm-auto center-in-column  text-center">
              <div className="spacing"></div>
              <div className="card circle circle--40">
                <FontAwesomeIcon icon={['fas','divide']} className="metric__icon text-primary" fixedWidth/>
              </div>

              <div className="spacing spacing--small-half"></div>

              <div className="text-small text-muted">Dividerat på</div>
              <div className="spacing"></div>
            </div>
            <BrandMetricCard metricValue={stats.sessions} metricTitle="Sessioner"
              metricDescription="Antal gånger användare har sett chattboten"
              metricIcon={['far','comments']} 
              gradientStart="#ff4d5d" gradientEnd="#ff99a1"
            />

            <div className="col-lg-auto center-in-column text-center">
              <div className="spacing"></div>
              <div className="card circle circle--40">
                <FontAwesomeIcon icon={['fas','equals']} className="metric__icon text-primary" fixedWidth/>
              </div>

              <div className="spacing spacing--small-half"></div>

              <div className="text-small text-muted">Lika med</div>
              <div className="spacing"></div>
            </div>
            <BrandMetricCard metricValue={(((stats.conversations/stats.sessions)*100).toFixed(2)+"%")} metricTitle="Engagement Rate"
              metricDescription="Andel av användare som konverterat"
              metricIcon={['fas','hand-holding-heart']} 
              gradientStart="#0fc373" gradientEnd="#15f18f"
            />
          </div>
          <div className="spacing spacing--extra-large"></div>

          <h2>Rekommendationer</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="full-height-in-column">
                <div className="card card--fill">
                  {getComparisonBox((stats.conversations/stats.sessions), this.props.project.metricsComparison)}
                </div>
                <div className="spacing"></div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="full-height-in-column">
                <div className="card card--fill">
                  {getRecommendationBox((stats.conversations/stats.sessions), this.props.project.metricsComparison)}
                </div>
                <div className="spacing"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
}
export default Home;
