import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from '../layout/LoadingBar';
import MetricCard from '../layout/MetricCard';

class JobFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };

  }
  async componentDidMount() {
    if (this.props.project) {
      let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
      let path = '/getJob';
      let myInit = {
        body: { projectId: this.props.project.ProjectId, JobId: this.props.match.params.jobId }
      };

      API.post(apiName, path, myInit).then(response => {
        this.setState({ isLoaded: true, job: response.Job, funnel: response.funnel, stats: response.stats });
      }).catch(error => {
        console.log("error", error);
      });
    }
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }


  onChangeHandler(e) {
    var val = e.target.value;
    var newArray = val ? this.state.jobs.filter((d) => {
      return d.title.toLowerCase().indexOf(val.toLowerCase()) !== -1;
    }) : this.state.jobs;
    this.setState({
      jobList: newArray
    });
  }

  render() {
    const user = this.props.auth.user;
    const { job, funnel, stats, isLoaded } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (

      <div className="content mobile-menu-main">
      <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.props.project}/>
          <div class="spacing spacing--small"></div>
          <h1>{job.title}</h1>
          <div class="mw-paragraph">
          Analysera hur er bot konverterade era användare till kandidater för den här positionen.

          </div>
          <div className="spacing spacing--extra-large"></div>
          <h2>Kandidatresan</h2>
          <div className="row small-gutters">
            <MetricCard metricValue={funnel.sessions} metricTitle="Sessioner"
              metricDescription="Antal startade ansökningar"
              metricIcon={['far','eye']} 
              gradientStart="#ff4d5d" gradientEnd="#ff99a1"
            />
          
            <MetricCard metricValue={funnel.conversations} metricTitle="Konversationer"
              metricDescription="Antal gånger användare har interagerat minst en gång med er chattbot"
              metricIcon={['far','comments']} gradientStart="#2d398b" gradientEnd="#00aeef"
            />
            <MetricCard metricValue={stats.applications} metricTitle="Ansökningar"
              metricDescription="Antal ansökningar från användare"
              metricIcon={['far','file-alt']} gradientStart="#0fc373" gradientEnd="#15f18f"
            />
          </div>
          <div className="spacing spacing--small"></div>
          
          <div className="side-scroll">
            <div className="container">
              <div className="side-scroll__content">
                <div className="d-flex">
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          1
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Sessioner</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.sessions} ansökningar
                      </div> till den här positionen startade i chattboten
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${"0"}L100 ${this.getPercentOf(funnel.conversations, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask1)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
  
                        
                        <line x1="0" y1="0" x2="100" y2={this.getPercentOf(funnel.conversations, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                      <defs>
                        <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#7661ED"></stop>
                          <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  
                  <div className="h-spacing h-spacing--medium"></div>
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          2
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Konversationer</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.conversations} användare
                      </div> fortsatte interagera med chattboten för denna position
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${this.getPercentOf(funnel.conversations, funnel.sessions)}L100 ${this.getPercentOf(funnel.applications, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask2)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                        <line x1="0" y1={this.getPercentOf(funnel.conversations, funnel.sessions)} x2="100" y2={this.getPercentOf(funnel.applications, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                    </svg>
                  </div>
                  <div className="h-spacing h-spacing--medium"></div>
                  
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          3
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Kandidater</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.applications} användare
                      </div> blev kandidater
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${this.getPercentOf(funnel.applications, funnel.sessions)}L100 ${this.getPercentOf(funnel.applications, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask3)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                        <line x1="0" y1={this.getPercentOf(funnel.applications, funnel.sessions)} x2="100" y2={this.getPercentOf(funnel.applications, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                    </svg>
                  </div>
                <div className="h-spacing h-spacing--medium"></div>

              </div>
            </div>
          </div>
          </div>
          <div className="spacing"></div>
          <div className="spacing"></div>
        </div>
      </div>
    ));
  }
}
export default JobFlow;
