import React from 'react';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import JobRow from './JobRow.js';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';

class JobsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };

  }
  async componentDidMount() {
    const { project, startDate, endDate } = this.props;
    if (project && startDate && endDate) {
      this.getData(startDate, endDate);
    }
  }

  getData(startDate, endDate) {
    const { project, formatDate } = this.props;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/getJobs';
    let myInit = {
      body: { projectId: project.ProjectId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
    };

    API.post(apiName, path, myInit).then(response => {
      this.setState({ isLoaded: true, jobs: response.jobs, funnel: response.funnel, jobList: response.jobs });
    }).catch(error => {
      console.log("error", error);
    });
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  setStartDate(date) {
    this.getData(date, this.props.endDate);
    this.setState({ isLoaded: false });
    this.props.setStartDate(date);
  }
  setEndDate(date) {
    this.getData(this.props.startDate, date);
    this.setState({ isLoaded: false });
    this.props.setEndDate(date);
  }
  onChangeHandler(e) {
    var val = e.target.value;
    var newArray = val ? this.state.jobs.filter((d) => {
      return d.title.toLowerCase().indexOf(val.toLowerCase()) !== -1;
    }) : this.state.jobs;
    this.setState({
      jobList: newArray
    });
  }

  render() {
    const user = this.props.auth.user;
    const { project, handleLogOut, projectStartDate, defaultStartDate, startDate, endDate } = this.props;
    const { jobList, funnel, isLoaded } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableApplicationMetrics ? <DisabledMetrics /> : (
      <div className="content mobile-menu-main">
        <div className="container">
          <div className="spacing"></div>
          <Toolbar handleLogOut={handleLogOut} user={user} project={project} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} setStartDate={this.setStartDate.bind(this)}  setEndDate={this.setEndDate.bind(this)} />
          <div className="spacing spacing--small"></div>
          <h1>Jobbflöden </h1>
          <div className="mw-paragraph">
          Översikten ger dig en vy över er chattbot-statistik när boten är placerad på specifika jobbsidor.
          Ta reda på vilka jobb som flest söker efter och få en känsla av hur er chattbot konverterar dina användare till potentiella eller faktiska kandidater.
          </div>
          <div className="spacing"></div>
          <h2>Kandidatresan</h2>
          <div className="side-scroll">
            <div className="container">
              <div className="side-scroll__content">
                <div className="d-flex">
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          1
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Sessioner</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.sessions} ansökningar 
                      </div> startade i chattboten
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${"0"}L100 ${this.getPercentOf(funnel.conversations, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask1)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                        <line x1="0" y1="0" x2="100" y2={this.getPercentOf(funnel.conversations, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                      <defs>
                        <linearGradient id="gradient_fill" x1="100" y1="0" x2="100" y2="100" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#7661ED"></stop>
                          <stop offset="1" stopColor="#7661ED" stopOpacity="0" ></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  
                  <div className="h-spacing h-spacing--medium"></div>
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          2
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Konversationer</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.conversations} användare 
                      </div> fortsatte att interagera med chattboten
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${this.getPercentOf(funnel.conversations, funnel.sessions)}L100 ${this.getPercentOf(funnel.applications, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask2)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                        <line x1="0" y1={this.getPercentOf(funnel.conversations, funnel.sessions)} x2="100" y2={this.getPercentOf(funnel.applications, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                    </svg>
                  </div>
                  <div className="h-spacing h-spacing--medium"></div>
                  
                  <div className="card card--vertical">
                    <div className="c-spacing c-spacing--medium" style={{height:'9em'}}>
                      <div className="step">
                        <div className="step__circle">
                          3
                        </div>
                        <div className="h-spacing h-spacing--small"></div>
                        <b>Kandidater</b>
                      </div>
                      <div className="spacing spacing--small"></div>
                      <div className="separator-h"></div>
                      <div className="spacing spacing--small"></div>
                      <div className="text-primary font-weight-bold d-inline-block">
                        {funnel.applications} användare 
                      </div> blev kandidater
                      <div className="spacing spacing--small"></div>
                    </div>
                    <svg width="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                        <path d={`M0 ${this.getPercentOf(funnel.applications, funnel.sessions)}L100 ${this.getPercentOf(funnel.applications, funnel.sessions)}V100H0V0Z`} fill="white"></path>
                      </mask>
                      <g mask="url(#mask3)">
                        <path opacity="0.2" d="M0 0L100 0V161H0V0Z" fill="url(#gradient_fill)"></path>
                        <line x1="0" y1={this.getPercentOf(funnel.applications, funnel.sessions)} x2="100" y2={this.getPercentOf(funnel.applications, funnel.sessions)} style={{stroke:"#7661ED", strokeWidth:"2"}}></line>
                      </g>
                    </svg>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>



          <div className="spacing"></div>
          <div className="row no-gutters align-items-center">
            <div className="col-sm-auto">
              <h2 className="mb-0">Alla flöden</h2>
            </div>
            <div className="col-sm-auto">
              <div className="h-spacing h-spacing--medium"></div>
            </div>
            <div className="col-sm-auto">
              <div className="d-sm-none spacing"></div>
              <div className="search">
                <input className="search__field" type="search" placeholder="Sök..." value={this.state.input} onChange={this.onChangeHandler.bind(this)}/>
                <i className="search__icon far fa-search"></i>
              </div>
            </div>
          </div>
          <div className="spacing"></div>

          <div className="card card__table text-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <span className="table__sortable table__sortable--active">
                      Jobb <i className="fas fa-caret-down"></i>
                    </span>
                  </th>
                  <th><span className="table__sortable">Plats</span></th>
                  <th><span className="table__sortable">Kandidater</span></th>
                </tr>
              </thead>
              <tbody>
              {jobList && jobList.map(job => 
                <JobRow job={job} key={job.JobId} />
              )}
                
              </tbody>
            </table>

            <div className="separator-h"></div>
          </div>
          <div className="spacing"></div>
          <div className="spacing"></div>
        </div>
      </div>
      )));
  }
}
export default JobsOverview;
