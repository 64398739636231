import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import config from '../../config';
import LoadingBar from '../layout/LoadingBar';
import DisabledMetrics from '../home/DisabledMetrics';
import MetricCard from '../layout/MetricCard';


class FacebookMessenger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
      isLoaded: false
    };

  }

  async componentDidMount() {
    const { project, startDate, endDate } = this.props;
    if (project && startDate && endDate) {
      this.getData(startDate, endDate);
    }
  }

  getData(startDate, endDate) {
    const { project, formatDate } = this.props;

    let apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiName;
    let path = '/facebookMessenger';
    let myInit = {
      body: { projectId: project.ProjectId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
    };

    API.post(apiName, path, myInit).then(response => {
      this.setState({ isLoaded: true, stats: response.stats});
    }).catch(error => {
      console.log("error", error);
    });
  }

  getPercentOf(a, total) {
    var x = a / total;
    x = x * 100;
    return Math.round(100 - x);
  }

  setStartDate(date) {
    this.getData(date, this.props.endDate);
    this.setState({ isLoaded: false });
    this.props.setStartDate(date);
  }
  setEndDate(date) {
    this.getData(this.props.startDate, date);
    this.setState({ isLoaded: false });
    this.props.setEndDate(date);
  }

  render() {
    const user = this.props.auth.user;
    const { project, handleLogOut, projectStartDate, defaultStartDate, startDate, endDate } = this.props;

    const { stats, isLoaded } = this.state;
    return (!isLoaded ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      this.props.project.disableAiMetrics ? <DisabledMetrics /> : (
      <div className="content mobile-menu-main">
        <div className="container">
          <div className="spacing"></div>
            <Toolbar handleLogOut={handleLogOut} user={user} project={project} projectStartDate={projectStartDate} defaultStartDate={defaultStartDate} startDate={startDate} endDate={endDate} setStartDate={this.setStartDate.bind(this)}  setEndDate={this.setEndDate.bind(this)} />

          <div className="spacing spacing--small"></div>
          <h1>
            <span className="mr-1">Facebook Messenger</span>
            <span style={{'fontSize': '1rem'}} className="font-weight-bold text-muted">
              Analys
            </span>
          </h1>

          <div className="mw-paragraph">
            BEHÖVER COPY
          </div>
          <div className="spacing spacing--medium"></div>
          <h2>ChatBott-statistik</h2>
          <div className="row small-gutters">
            <MetricCard metricValue={stats.users} metricTitle="Användare"
              metricDescription="Antal användare som använt chattboten"
              metricIcon={['fas','user-friends']} 
              gradientStart="#fbb040" gradientEnd="#f9ed32"
            />
            
            <MetricCard metricValue={stats.conversations} metricTitle="Konversationer"
              metricDescription="Antal gånger användare har interagerat minst en gång med AI"
              metricIcon={['far','comments']} gradientStart="#2d398b" gradientEnd="#00aeef"
            />
            {!this.props.project.disableApplicationMetrics &&
            <MetricCard metricValue={stats.applications} metricTitle="Ansökningar"
              metricDescription="Totala antalet ansökningar till positionen"
              metricIcon={['far','file-alt']} gradientStart="#0fc373" gradientEnd="#15f18f"
            />
            }
          </div>
          
          <div className="spacing spacing--small"></div>
          <div className="spacing"></div>

        </div>
      </div>
      )));
  }
}
export default FacebookMessenger;
