import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from 'aws-amplify';
import Toolbar from '../layout/Toolbar';
import LoadingBar from '../layout/LoadingBar';
import ProjectCard from './ProjectCard';

class ProjectsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileMenu: false,
    };
  }

  toggleProfileMenu() {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  }

  pickProject(project) {
    this.props.setProject(project);
  }
  setProject(project){
    this.props.setProject(project);
  }
  render() {
    const user = this.props.auth.user;
    let { projects } = this.props;

    return (!projects ? <div className="loading-wrapper"> <LoadingBar /></div> : (
      <div className="content mobile-menu-main">
        <div className="container">
            <div className="spacing"></div>
            {this.state.project &&
            <Toolbar handleLogOut={this.props.handleLogOut} user={user} project={this.state.project}/>}
            <div className="spacing spacing--small"></div>
            <h1>
              <span className="mr-1">Dina projekt</span>
            </h1>
        
            <div className="mw-paragraph">
              Välj ett av dina projekt nedan för att se er data.
            </div>
            <div className="spacing spacing--extra-large"></div>
            <div className="row small-gutters">
            { projects && projects.map(project => {return (
        
            <ProjectCard projectName={project.name}
              projectIcon={['fas','tasks']} 
              gradientStart="#48399e" gradientEnd="#8868e8"
              setProject={this.setProject.bind(this)}
              project={project}
              key={project.ProjectId}
            />
             
              );
            })}
            </div>
          </div>
        </div>
    ));
  }
}
export default ProjectsOverview;
